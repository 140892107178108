import masonry from './masonry'

import mime from 'mime-types'
import * as Fb from '@/fb'

import Vue from 'vue'
import Vuex from 'vuex'

import Thumbnail from '@/classes/thumbnail'
import md5 from 'crypto-js/md5'

import BrowserImageManipulation from 'browser-image-manipulation'
import { folderFunctions, libraryFunctions, getPartsQuery } from '@/fb'
import router from '@/router'

Vue.use(Vuex)

let cntOnPage
if (window.innerWidth >= 1920) {
  cntOnPage = 30
}

if (window.innerWidth > 1680 && window.innerWidth < 1920) {
  cntOnPage = 30
}

if (window.innerWidth <= 1680 && window.innerWidth > 800) {
  cntOnPage = 30
}

if (window.innerWidth <= 800) {
  cntOnPage = 30
}

export const zerofill = i => {
  return (i < 10 ? '0' : '') + i
}

const createTreeFromArray = (list, storeState) => {
  if (!list.length) return list
  let map = {}, node, roots = [], i
  
  for (i = 0; i < list.length; i += 1) {
    map[list[i][0]] = i
    list[i][1].children = []
    list[i][1].docId = list[i][0]
  }
  
  for (i = 0; i < list.length; i += 1) {
    node = list[i][1]
    node.data = {
      ...node
    }

    node.isExpanded = !!(storeState.activeFolder && storeState.activeFolder[0] === list[i][0])
    node.isExpanded = true
    node.isDraggable = true
    node.ind = node.index
    // TODO РАБОТА С ПЕРЕМЕЩЕНИЕМ + ПЕРЕСЧЕТ ИНДЕКСОВ ( ПРИ ДРАГЕ И ЧЕРЕЗ MOVE TO)
    console.log(node)
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      if (list[map[node.parentId]]) {
        list[map[node.parentId]][1].children.push(node)
      }
    } else {
      roots.push(node)
    }
  }

  return roots
}

export default new Vuex.Store({
  state: {
    linearZoomArray: [],
    uploadModel: false,
    currentDragElement: [null, null],
    currentEnteredElement: [null, null],
    isOffline: false,
    isOfflineActionModel: false,
    createDocumentProcess: false,
    fbUpdateListeners: [],
    user: null,
    userSettings: null,
    subscription: null,
    plan: null,
    columnWidth: 300,
    columnCount: 0,
    cntOnPage,
    listFileIdsInLoadProcess: [],
    listFilesInUploadProcess: {},
    filesInLoadProcess: false,
    listFilesInUpdateProcess: false,
    tree: [],
    lastItem: null,
    editFolderDialogModel: false,
    editFolderDialogItem: null,
    editLibraryDialogModel: false,
    editLibraryDialogItem: null,
    virtualTree: [
      {
        subheader: 'Library',
        items: [
          { 
            id: "inbox",
            title: "Inbox",
            icon: "mdi-inbox"
          }, 
          {
            id: "recent",
            title: "Recent",
            icon: "mdi-earth"
          },
          {
            id: "starred",
            title: "Favorites",
            icon: "mdi-heart"
          },
          {
            id: "trash",
            title: "Trash",
            icon: 'mdi-trash-can-outline'
          }
        ]
      },
      {
        subheader: 'Folders',
        id: 'folders',
        items: [
          {
            id: "home",
            title: "Library",
            multiple: true,
            icon: 'mdi-folder'
          }
        ]
      }
        
      // {
      //   subheader: 'Filter by',
      //   items: [
      //     { 
      //       id: 'image',
      //       title: 'Images',
      //       icon: 'mdi-image'
      //     },
      //     {
      //       id: 'video',
      //       title: 'Videos',
      //       icon: 'mdi-video'
      //     },
      //     {
      //       id: 'note',
      //       title: 'Notes',
      //       icon: 'mdi-text'
      //     },
      //     {
      //       id: 'quote',
      //       title: 'Quotes',
      //       icon: 'mdi-comment-quote'
      //     }
      //   ]
      // }
    ],
    files: [],
    folders: [],
    libraries: [],
    types: [],
    treeFbId: null,
    activeLibrary: [],
    activeFolder: [],
    moveToDialog: {
      model: false,
      target: null,
      el: null
    },
    itemDialog: {
      loading: false,
      model: false,
      item: null,
      type: 'preview'
    }
  },
  mutations: {
    setItemDialogLoading: (state, val) => Vue.set(state.itemDialog, 'loading', val),
    setItemDialogModel: (state, val) => Vue.set(state.itemDialog, 'model', val),
    setItemDialogItem: (state, val) => Vue.set(state.itemDialog, 'item', val),
    setItemDialogType: (state, val) => Vue.set(state.itemDialog, 'type', val),
    setUploadModel(state, val) {
      state.uploadModel = val
    },
    setMoveToDialog(state, val) {
      state.moveToDialog = val
    },
    setMoveToDialogModel(state, val) {
      state.moveToDialog.model = val
    },
    setMoveToDialogTarget(state, val) {
      state.moveToDialog.target = val
    },
    setMoveToDialogEl(state, val) {
      state.moveToDialog.el = val
    },
    setCurrentDragElement(state, elem) {
      const [ id ] = elem
      id ? document.body.classList.add('in-drag-item-process') : document.body.classList.remove('in-drag-item-process')
      state.currentDragElement = elem
    },
    setCurrentEnteredElement(state, elem) {
      state.currentEnteredElement = elem
    },
    setEditFolderDialogModel(state, val) {
      state.editFolderDialogModel = val
    },
    setEditFolderDialogItem(state, val) {
      state.editFolderDialogItem = val
    },
    setEditLibraryDialogModel(state, val) {
      state.editLibraryDialogModel = val
    },
    setEditLibraryDialogItem(state, val) {
      state.editLibraryDialogItem = val
    },
    setIsOffline(state, val) {
      state.isOffline = val
    },
    setIsOfflineActionModel(state, val) {
      state.isOfflineActionModel = val
    },
    setColumnWidth(state, val) {
      state.columnWidth = val
    },
    setColumnCount(state, val) {
      state.columnCount = val
    },
    setLibraries(state, val = []) {
      state.libraries = val
    },
    setFolders(state, val = []) {
      state.folders = val
      state.tree = state.folders.length ? createTreeFromArray(state.folders, state) : []
    },
    setListFilesInUpdateProcess(state, val) {
      state.listFilesInUpdateProcess = val
    },
    addListFileIdsInLoadProcess(state, val) {
      state.listFileIdsInLoadProcess = [...state.listFileIdsInLoadProcess, val]
    },
    removeListFileIdsInLoadProcess(state, val) {
      state.listFileIdsInLoadProcess = state.listFileIdsInLoadProcess.filter(id => id !== val)
    },
    addListFilesInUploadProcess(state, val) {
      const newState = {...state.listFilesInUploadProcess}
      const { documentId } = val
      Vue.set(newState, documentId, val)
      state.listFilesInUploadProcess = newState
      //localStorage.setItem('il-downloads', JSON.stringify(state.listFilesInUploadProcess))
    },
    setListFilesInUploadProcess(state, val) {
      state.listFilesInUploadProcess = val
    },
    removeListFilesInUploadProcess(state, val) {
      const newState = [...state.listFilesInUploadProcess]
      delete newState[val] 
      state.listFilesInUploadProcess = newState
      //localStorage.setItem('il-downloads', JSON.stringify(state.listFilesInUploadProcess))
    },
    setFbUpdateListeners(state, val) {
      state.fbUpdateListeners.forEach(destroyListener => {
        destroyListener()
      })
      state.fbUpdateListeners = val
    },
    pushFbUpdateListener(state, val) {
      state.fbUpdateListeners.push(val)
    },
    setCntOnPage(state, val) {
      state.cntOnPage = val
    },
    setLastItem(state, val) {
      state.lastItem = val
    },
    setTreeFbId(state, val) {
      state.treeFbId = val
    },
    setFilesInLoadProcess(state, val) {
      state.filesInLoadProcess = val
    },
    setUser(state, val) {
      state.user = val
    },
    setUserSettings(state, val) {
      state.userSettings = val
    },
    setSubscription(state, val) {
      state.subscription = val
    },
    setPlan(state, val) {
      state.plan = val
    },
    setTreeModel(state, val) {
      state.treeModel = val
    },
    saveTreeInStorage(state) {
      localStorage.setItem('il-folders', JSON.stringify(state.tree))
    },
    saveFilesInStorage(state) {
      localStorage.setItem('il-documents', JSON.stringify(state.files))
    },
    setTree(state, val) {
      state.tree = val
    },
    setVirtualTree(state, val) {
      state.virtualTree = val
    },
    setFiles(state, val) {
      state.files = val
    },
    setFileTypes(state, val) {
      state.types = val
    },
    setActiveLibrary(state, payload = null) {
      state.activeLibrary = payload
      payload && payload[0] && localStorage.setItem('il-active-library', JSON.stringify(payload[0]))
    },
    setActiveFolder(state, payload = null) {
      const isArray = Array.isArray(payload)
      const val = isArray ? payload : payload.val
      let id = val[0]
      const result = state.virtualTree.reduce((acc, item) => {
        acc = [...acc, ...item.items]
        return acc
      }, [])
      const virtualTreeIds = result.map(item => item.id)
      if (val.length) {
        state.listFilesInUpdateProcess = false
        state.activeFolder = val
        id = state.activeFolder[0]
        localStorage.setItem('il-active-folder', id)
      }

      if (!isArray) return
      const path = virtualTreeIds.includes(id) ? `/${id}` : `/folders/${id}`
      router.push({ path }).catch(() => {})
    }
  },
  actions: {
    getDocumentById(context, id) {
      const ref = Fb.db.collection('documents')
      return ref.doc(id).get()
    },
    updateTreeInFb(context) {
      const folders = Fb.db.collection('folders')
      const { treeFbId } = context.state

      folders.doc(treeFbId).set({
        userId: context.state.user.uid,
        tree: context.getters.tree
      })
    },
    updateDocumentInFb(context, { item, id }) {
      return Fb.getAllUserDocumentsQuery(context.state.user.uid).doc(id).update({
        ...item,
        updatedAt: Fb.timestamp()
      })
        .then(doc => doc)
    },
    saveTreeInFb(context) {
      const { uid: userId } = context.state.user
      Fb.db.collection("folders").add({
        tree: context.getters.tree,
        userId
      })
        .then(docRef => {
          return docRef
        })
        .catch(err => {
          console.log(err)
          return null
        })

    },
    // File
    readFile(context, readableStream) {
      return new Promise((res) => {
        const reader = new FileReader()
        reader.addEventListener('', event => {
          const { result } = event.target
          return res(result)
        })
  
        reader.readAsText(readableStream)
      })
    },
    moveItem(context, { from, to, index, item }) {
      const filesKeys = Object.keys(context.state.files).filter(key => key !== index)
      const result = {}
      from
      item

      filesKeys.forEach(key => {
        result[key] = context.state.files[key]
      })

      return context.dispatch('updateDocumentInFb', {
        item: {
          folderId: to.docId,
          updatedAt: Fb.timestamp()
        },
        id: index
      })
        .then(() => {
          context.commit('setFiles', result)
        })
    },
    createThumbnail(context, { path, original, id, width, height }) {
      return new Promise((res) => {
        try {
          const { type } = original
          const isVertical = height > width
          const maxHeight = isVertical ? 10000 : 200
          const maxWidth = isVertical ? 200 : 10000
          const im = new BrowserImageManipulation()
            .loadBlob(original, {fixOrientation: true })
            .resize(maxWidth, maxHeight, {
              pica: {
                alpha: true
              }
            })

          im.saveAsBlob(type)
          .then(blob => {
            const {type, name} = blob
            const thumbFile = new File([blob], name, {
              type
            })

            path 
            id

            Fb.addToStorage(path, thumbFile, type, id, true)
              .then(link => res(link))
          })

        } catch(e) {
          console.log('Generate thumbnail error', e)
        }
      })
    },
    async loadLibraries(context, userId = context.state.user.uid) {
      const resp = await Fb.getAllLibrariesQuery(userId).get()
      const { docs = []} = resp
      return docs.map(doc => [ doc.id, doc.data()])
    },
    async createLibrary(context, item) {
      const { user } = context.state
      const { uid: currentUserId } = user

      if (!item.userId) {
        item.userId = currentUserId
      }

      const librariesRef = Fb.db.collection('libraries')
      const withoutId = { ...item }
      delete withoutId.id

      return librariesRef.doc(item.id).set(withoutId)
        .then(ref => {
          return ref
        })
    },
    async editLibrary(context, payload) {
      payload
    },
    async deleteLibrary(context, id) {
      const ref = Fb.db.collection('libraries').doc(id)

      return ref.delete()
    },
    createItem(context, { item, width = null, height = null}) {
      return new Promise(res => {
        const libraryId = context.getters.activeLibrary[0]
        const documentsRef = Fb.db.collection('documents')
        const { type } = item
        let original = item.content && item.content.original || null
        let newItem = {
          ...item,
          libraryId
        }

        if (type !== 'multipart' && type !== 'quote' && type !== 'note') {
          const md5FromOriginal = md5(original).toString()
          const aspectRatio = (width / height).toFixed(2)
          const thumbnail = new Thumbnail({ original: null , aspectRatio })
          newItem = {
            ...newItem,
            content: {
              ...newItem.content,
              original: null,
              originalUrl: null
            },
            metaInfo: {
              ...newItem.metaInfo,
              hash: md5FromOriginal
            },
            thumbnail:  Object.assign({}, thumbnail)
          }
        }

        context.state.createDocumentProcess = true
        documentsRef.add(newItem)
          .then(docRef => {
            context.state.createDocumentProcess = false
            const { id } = docRef
            if (type !== 'multipart' && type !== 'quote' && type !== 'note') {
              context.commit('addListFileIdsInLoadProcess', id)
            }
            context.dispatch('getDocumentById', id)
              .then(doc => {
                const data = doc.data()
                const finalize = () => {
                  return res({
                    docId: id,
                    type
                  })
                }
                if (type === 'multipart') {
                  return finalize()
                }
                if (type !== 'quote' && type !== 'note') {
                  const { type: mimeType } = original
                  const ext = mime.extension(mimeType)
                  const { type } = newItem
                  const toStorage = `users/${context.state.user.uid}/documents/${id}/${id}.${ext}`
                  const toStorageThumb  = `users/${context.state.user.uid}/documents/${id}/${id}_thumb.${ext}`
                  Fb.addToStorage(toStorage, original, mimeType, id, true, data)
                    .then(async originalLink => {
                      const originalUrl = await Fb.getStorageDownloadURL(originalLink)
                      context.dispatch('createThumbnail', {
                        path: toStorageThumb,
                        original,
                        id,
                        width,
                        height
                      })
                      .then(thumbnailOriginalLink => {
                        Promise.all([
                          Fb.getStorageDownloadURL(thumbnailOriginalLink)
                        ])
                          .then(async originals => {
                            const [ thumbnailOriginalUrl ] = originals
                            const item = {
                              [id]: {
                              ...data,
                              content: {
                                ...data.content,
                                original: originalLink,
                                originalUrl
                              }
                            }}

                            item[id].thumbnail.original = thumbnailOriginalLink
                            item[id].thumbnail.originalUrl = thumbnailOriginalUrl

                            const dataForUpdate = {
                              'content.original': originalLink,
                              'thumbnail.original': item[id].thumbnail.original,
                              updatedAt: Fb.timestamp()
                            }

                            Fb.db.collection('documents').doc(id).update(dataForUpdate)
                              .then(() => {
                                context.dispatch('getDocumentById', id)
                                  .then(doc => {
                                    const resultItem = {
                                      ...doc.data(),
                                      ...item[id]
                                    }
                                    
                                    resultItem // Подумать о том, что бы его переместить

                                    res({docId: id, item, type})
                                  })
                              })

                          })
                      })
                    })
                } else {
                  finalize()
                }
              })
          })
      })
    },
    async clearTrash(context) {
      const { user } = context.state
      const { uid: userId } = user
      const snapshot = await Fb.db.collection('documents')
        .where('userId', '==', userId)
        .where('deleted', '==', true)
        .get()
      const documents = snapshot.docs.map(ref => {
        const { id } = ref
        const data = ref.data()
        return {
          id,
          data,
          ref
        }
      })
      const forever = true
      await Promise.all(documents.map(doc => {
        return context.dispatch('deleteItem', {
          id: doc.id,
          item: doc.data,
          forever
        })
      }))
      return true
    },
    deleteItem(context, { id, item, forever }) {
      return new Promise(res => {
        const deletedAt = Fb.timestamp()
        if (!forever) {
          const toEdit = {
            id,
            item: {
              ...item,
              deletedAt,
              deleted: true
            }
          }

          return context.dispatch('editItem', toEdit)
            .then(updatedItem => res(updatedItem))
        } else {
          const { type } = item
          const { original } = item.content
          return Fb.db.collection("documents").doc(id).delete()
            .then(() => {
              if (type === 'note' || type === 'quote') {
                res(item)
              } else {
                if (!original) {
                  res(item)
                  return item
                }
                
                return context.dispatch('deleteOriginal', item)
                  .then(res(item))
              }
            })
            .catch(function(error) {
              console.error("Error removing document: ", error);
            })
        }
      })
    },
    async deleteFile(context, { fullPath }) {
      const ref = Fb.storage.ref(fullPath)

      try {
        await ref.delete()
      } catch(err) {
        console.log('Delete file error: ', err)
      }

      return ref
    },
    async deleteFolderContents(context, path) {
      const ref = Fb.storage.ref(path)
      
      try {
        const { items: files, prefixes: dirs } = await ref.listAll()
        
        await Promise.all(files.map(async ({ fullPath, name }) => {
          await context.dispatch('deleteFile', {
            fullPath,
            name
          })
        }))
        
        await Promise.all(dirs.map(async ({ fullPath }) => {
          await context.dispatch('deleteFolderContents', fullPath)
        }))

        console.log('Deleted folder: ', path)

      } catch (err) {
        console.log('Delete dir error: ', err)
      }
      return ref
    },
    async deleteOriginal(context, item) {
      const { original } = item.content
      const splited = original.split('/')
      const toFolderSplited = splited.slice(0, splited.length - 1)
      const lastFolderPart = toFolderSplited[toFolderSplited.length - 1]
      const folder = toFolderSplited.join('/')
      const lastFolderPartIsNumber = !isNaN(lastFolderPart) && !isNaN(parseFloat(lastFolderPart))
      if (lastFolderPartIsNumber) {
        folder
      } else {
        const ref = await context.dispatch('deleteFolderContents', folder)
        return ref
      }
    },
    editItem(context, payload) {
      return context.dispatch('updateDocumentInFb', {
        ...payload
      })
        .then(() => {
          return context.dispatch('getDocumentById', payload.id)
            .then(doc => {
              return [doc.id, doc.data()]
            })
        })
    },

    // Load base data
    loadFileParts(context, docId) {
      return getPartsQuery(docId).get()
        .then(snapshot => snapshot.docs)
        .then(docs => docs.map(doc => [doc.id, doc.data()]))
    },
    loadFileTypes(context) {
      return fetch('/default-types.json', {
        method: 'get'
      })
        .then(res => {
          const { status } = res
          if (status === 200) {
            return res.json()
              .then(data => {
                const { types } = data
                context.commit('setFileTypes', types)
              })
          }
        })
    },
    async updateFiles(context, activeFolder) {
      if (context.state.listFilesInUpdateProcess) return { docs: []}
      if (!context.getters.lastItem || !Object.keys(context.getters.lastItem).length) {
        return
      }
      console.log('start update!', activeFolder)
      context.commit('setListFilesInUpdateProcess', true)
      let query
      const { uid: userId } = context.state.user
      const folderName = activeFolder[0].toUpperCase() + activeFolder.slice(1)
      console.log('Активная папка: ', folderName)
      console.log('Загрузка...')

      const allVirtualFolderNames = context.state.virtualTree.reduce((acc, treeItem) => {
        acc = [...acc, ...treeItem.items.map(item => item.id)]
        return acc
      }, [])

      if (allVirtualFolderNames.includes(activeFolder)) {
        context.commit('pushFbUpdateListener', Fb[`onUpdate${folderName}After`](userId))
        query = Fb[`get${folderName}QueryAfter`](userId)
      } else {
        context.commit('pushFbUpdateListener', Fb.onUpdateFolderByIdAfter(userId, activeFolder))
        query = Fb.getFolderByIdQueryAfter(userId, activeFolder)
      }

      const querySnapshot = await query.get()
      await context.dispatch('workWithSnapshotFiles', querySnapshot)
      context.commit('setListFilesInUpdateProcess', false)
      return querySnapshot
    },
    workWithSnapshotFiles(context, querySnapshot) {
      return new Promise(res => {
        const result = []
        querySnapshot.forEach(doc => {
          const item = [doc.id, doc.data()]
          const { type } = item[1]
          if (type !== 'multipart' && type !== 'note' && type !== 'quote') {
            const { original } = item[1].content
            if (!original || original.indexOf('https') !== -1) {
              item[1].content.originalUrl = original
            } 
          }

          result.push(item)
        })
        
        res(result)
      })
    },
    loadFiles(context, { isFirst, folderId }) {
      console.log(folderId)
      const folderIdValue = folderId === 'recent' ? 'inbox' : folderId
      if (isFirst) {
        context.commit('setFilesInLoadProcess', true)
      }

      const allVirtualFolderNames = context.state.virtualTree.reduce((acc, treeItem) => {
        acc = [...acc, ...treeItem.items.map(item => item.id)]
        return acc
      }, [])

      if (!allVirtualFolderNames.includes(folderId)) {
        context.commit('pushFbUpdateListener', Fb.onUpdateFolderById(context.state.user.uid, folderId))
        return Fb.getFolderByIdQuery(context.state.user.uid, folderIdValue).get()
          .then(querySnapshot => context.dispatch('loadFilesSuccess', querySnapshot))
          .catch(err => context.dispatch('loadFilesError', err))
      } else {
        const folderName = folderId[0].toUpperCase() + folderId.slice(1)
        const updateActionName = `onUpdate${folderName}`
        const queryActionName = `get${folderName}Query`
        context.commit('pushFbUpdateListener', Fb[updateActionName](context.state.user.uid))
        return Fb[queryActionName](context.state.user.uid).get()
          .then(querySnapshot => context.dispatch('loadFilesSuccess', querySnapshot))
          .catch(err => context.dispatch('loadFilesError', err))
      }
    },
    async loadFilesSuccess(context, querySnapshot) {
      context.commit('setFilesInLoadProcess', false)
      const lastItem = querySnapshot.docs[querySnapshot.docs.length - 1]
      context.commit('setLastItem', lastItem)
    },
    loadFilesError(context, err) {
      console.log(err)
    },
    loadFolders(context) {
      return Fb.getTreeQuery(context.state.user.uid).get()
        .catch(err => {
          console.log(err)
        })
    },

    // Folder
    async folderByParentId(context, parentId = context.state.activeFolder[0]) {
      const { docs  = []} = await Fb.getFoldersByParentId(context.state.user.uid, parentId).get()
      return docs.map(doc => [doc.id, doc.data()])
    },
    editItemInFolder(context, payload) {
      return folderFunctions.updateFolder(payload)
    },
    editLibraryItem(context, payload) {
      return libraryFunctions.updateLibrary(payload)
    },
    async addItemInFolder(context, { folder, item }) {
      const { activeLibrary } = context.getters
      const parentId = folder && (folder.data && folder.data.docId || folder.docId) || null
      const childrensInFolder = await context.dispatch('folderByParentId', parentId)
      const index = childrensInFolder.length

      return new Promise(res => {
        const resultItem = {
          ...item,
          index,
          createdAt: Fb.timestamp(),
          parentId,
          libraryId: activeLibrary && activeLibrary[0] || null
        }

        folderFunctions.addFolder(resultItem)
          .then(ref => ref.get())
          .then(ref => {
            console.log(ref.data())
            res(ref.data())
          })
      })
    },
    findFilesInFolder(context, folderId) {
      context
      return new Promise(res => {
        return Fb.getFilesInFolderQuery(context.state.user.uid, folderId)
          .get()
          .then(querySnapshot => {
            return res(querySnapshot.docs.map(doc => doc.id))
          })
      })
    },
    deleteFolders(context, foldersIds) {
      return Promise.all(foldersIds.map(folderId => folderFunctions.deleteFolder(folderId, context.state.user.uid)))
    },
    async deleteItems(context, folderIds) {
      const documentsRef = Fb.db.collection('documents')
      await context.dispatch('deleteFolders', folderIds)
      return Promise.all(folderIds.map(id => {
        return context.dispatch('findFilesInFolder', id)
          .then(docsIds => {
            if (docsIds.length) {
              Promise.all(docsIds.map(id => {
                documentsRef.doc(id).update({
                  deleted: true,
                  deletedAt: Fb.timestamp()
                })
              }))
            }

            return {
              [id]: docsIds
            }
          })
      }))
    },
    saveUserProfile(context, data) {
      return Fb.updateUserProfile(data)
        .then(() => {
          context.commit('setUser', {
            ...context.state.user,
            ...data
          })
        })
    },
    createUserSubscription(context) {
      const { uid: userId } = context.state.user
      return Fb.createUserSubscription(userId)
        .then(subscription => {
          return subscription
        })
    },
    getUserPlan(context, subscriptionId) {
      return Fb.getUserPlan(subscriptionId).get()
        .then(doc => {
          const { docs } = doc
          const plan = docs[0].data()
          return plan
        })
    },
    getUserSubscription(context) {
      const { uid: userId } = context.state.user
      if (!userId) return
      return Fb.getUserSubscription(userId).get()
        .then(doc => {
          const { docs, empty } = doc
          if (empty) {
            return null
          } else {
            const subscription = docs[0].data()
            return subscription
          }
        })
    },
  },
  getters: {
    isOffline: state => state.isOffline,
    isOfflineActionModel: state => state.isOfflineActionModel,
    activeLibraryById: (state, getters) => id => getters.libraries.find(l => l[0] === id),
    itemDialog: state => state.itemDialog,
    itemDialogLoading: state => state.itemDialog.loading,
    itemDialogModel: state => state.itemDialog.model,
    itemDialogItem: state => state.itemDialog.item,
    itemDialogType: state => state.itemDialog.type,
    uploadModel: state => state.uploadModel,
    moveToDialog: state => state.moveToDialog,
    moveToDialogModel: state => state.moveToDialog.model,
    moveToDialogTarget: state => state.moveToDialog.target,
    moveToDialogEl: state => state.moveToDialog.el,
    cntOnPage: state => state.cntOnPage,
    currentDragElement: state => state.currentDragElement,
    currentEnteredElement: state => state.currentEnteredElement,
    editFolderDialogModel: state => state.editFolderDialogModel,
    editFolderDialogItem: state => state.editFolderDialogItem,
    editLibraryDialogModel: state => state.editLibraryDialogModel,
    editLibraryDialogItem: state => state.editLibraryDialogItem,
    columnWidth: state => state.columnWidth,
    columnCount: state => state.columnCount,
    folders: state => state.folders,
    libraries: state => state.libraries,
    plan: state => state.plan,
    lastItem: state => state.lastItem,
    treeModel: state => state.treeModel,
    tree: state => state.tree,
    virtualTree: state => state.virtualTree,
    files: state => state.files,
    types: state => state.types,
    user: state => state.user,
    userSettings: state => state.userSettings,
    subscription: state => state.subscription,
    activeFolder: state => state.activeFolder,
    activeLibrary: state => state.activeLibrary,
    filesInLoadProcess: state => state.filesInLoadProcess,
    listFileIdsInLoadProcess: state => state.listFileIdsInLoadProcess,
    listFilesInUpdateProcess: state => state.listFilesInUpdateProcess,
    listFilesInUploadProcess: state => state.listFilesInUploadProcess
  },
  modules: {
    masonry
  }
})
