<template>
  <v-dialog
    v-if="show && item"
    v-bind="$attrs"
    transition="fade-transition"
    v-model="show"
    :max-width="fullscreenEnable ? '100%' : '90vw'"
    :fullscreen="fullscreenEnable"
    :dark="darkEnable"
    :content-class="`item-dialog pa-0 ${darkEnable && 'theme--dark'} ${item && item.type}-type ${!isMobileDevice && !fullscreenEnable && type === 'preview' ? 'transparent-toolbars' : ''}`"
    @click:outside="onClickOutside"
  >
    <input type="text" ref="copyInput" v-if="inCopyProcess" />
    <v-card
      class="d-flex flex-column"
      height="100%"
      style="border-radius: 0px;"
      :color="darkEnable ? `rgba(0, 0, 0, ${0.8})` : `rgba(255, 255, 255, ${transparent})`"
    >
      <template v-if="!loading">
        <item-dialog-toolbar
          :type="type"
          :item="item"
          style="z-index: 1000000;"
          v-model="toolbarTabModel"
          @clickOnClose="show = false"
          :tabs="tabs"
          @changeCommentsState="onChangeCommentsState"
        >
          <template v-if="item && item.type === 'image' && false"> <!-- TODO Временное сокрытие -->
            <v-spacer />
            <div class="d-flex align-center justify-center" style="max-width: 300px; width: 100%; margin: 0 auto;">
              <v-slider
                step="1"
                color="#fff"
                class="d-flex align-center justify-center"
                track-color="#fff"
                min="1"
                v-model="sliderModel"
                max="200"
                hide-details
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="onClickToActual"
                    dark
                  >
                    <v-icon>
                      mdi-image-size-select-actual
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    dark
                    @click="sliderModel = 100"  
                  >
                    <v-icon
                    >
                      mdi-fullscreen
                    </v-icon>
                  </v-btn>
                </template>
              </v-slider>
            </div>
          </template>
        </item-dialog-toolbar>
        <!-- <v-divider :dark="dark" v-if="!fullscreen" /> -->
        <template v-if="item.type === 'image'">
          <item-dialog-bar 
            class="item-dialog__bar d-flex flex-column justify-center align-items-center pt-0"
            :type="type"
            style="z-index: 1000000;"
            v-if="item && type === 'preview'"
            :item="item"
            :dark="darkEnable"
            @emitAction="onEmitAction"
          />
        </template>
        <v-card
          color="transparent"
          flat
          :dark="darkEnable"
          class="d-flex item-dialog__inner d-flex align-items-stretch"
        >
          <template v-if="item.type !== 'image'">
            <item-dialog-bar 
              class="item-dialog__bar d-flex flex-column justify-center align-items-center pt-0"
              :type="type"
              style="z-index: 1000000;"
              v-if="item && type === 'preview'"
              :item="item"
              :dark="darkEnable"
              @emitAction="onEmitAction"
            />
          </template>
          <div
            class="item-dialog__content custom-scroll flex-grow-1"
            style="z-index: 1;"
            :class="{
              'px-0': (type === 'edit' || type === 'create') && item && item.type === 'note'
            }"
          >
            <component
              v-if="item"
              :item="item"
              :item-id="itemId"
              ref="content"
              :is="typeContentComponent"
              :active-tab="toolbarTabModel"
              :tabs="tabs"
              :zoom="sliderModel"
              @updateZoom="val => sliderModel = val"
              @closed="onClosedFromPreview"
              @opened="onOpenedFromPreview"
              :broken-ids="brokenIds"
              :show-comments="showComments"
            />
          </div>
          <v-navigation-drawer
            right
            absolute
            :color="darkEnable ? '#000' : '#fff'"
            width="312"
            style="box-shadow: 0 8px 14px 0 rgb(68 52 77 / 16%), 0 4px 6px 0 rgb(46 32 54 / 24%);"
            v-model="showComments"
          >
            <item-dialog-comments />
          </v-navigation-drawer>
        </v-card>
        <!-- <v-divider :dark="dark" v-if="!fullscreen" /> -->
        <item-dialog-bottom-toolbar 
          :type="type"
          :dark="darkEnable"
          @clickCancel="onClickCancel"
          @clickSuccess="onClickSuccess"
          :loading="inLoadingProcess"
        />
      </template>
    </v-card>
  </v-dialog>
</template>
<script>

import { isMobile } from 'mobile-device-detect'
import loadItemDataMixin from '@/mixins/loadItemData'

const ItemDialogToolbar = () => import('./toolbar')
const ItemDialogBottomToolbar = () => import('./bottom-toolbar')
const ItemDialogComments = () => import('./comments')
const ItemDialogBar = () => import('./bar')

const EditType = () => import('./types/edit/index.vue')
const DeleteType = () => import('./types/delete')
const PreviewType = () => import('./types/preview')

export default {
  props: {
    pathAfterClose: {
      default: () => '/'
    },
    inLoadingProcess: {
      type: Boolean,
      default: () => false
    },
    index: {},
    type: {
      type: String,
      default: () => 'preview'
    },
    item: {
      required: true
    },
    value: {
      type: Boolean
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    fullscreen: {
      type: Boolean,
      default: () => false
    }
  },
  name: 'ItemDialog',
  mixins: [loadItemDataMixin],
  components: {
    ItemDialogToolbar,
    ItemDialogBottomToolbar,
    ItemDialogBar,
    ItemDialogComments,

    EditType,
    DeleteType,
    PreviewType
  },
  data: () => ({
    loading: false,
    show: true,
    inCopyProcess: false,
    brokenIds: [],
    sliderModel: 100,
    prevType: null,
    prevRoute: '/',
    showComments: false,
    toolbarTabModel: 0
  }),
  mounted() {
    if (!this.item) {
      if (this.type !== 'edit' && this.type !== 'preview') return
      this.loadItem()
    }

    this.setGlobalScroll(false)
  },
  
  destroyed() {
    this.setGlobalScroll(true)
  },
  computed: {
    route() {
      return this.$route
    },
    fullscreenEnable() {
      if (this.item && this.item.type === 'image') return true
      return this.fullscreen
    },
    darkEnable() {
      if (this.item && this.item.type === 'image') return true
      return this.dark
    },
    itemId() {
      const { documentId } = this.$route.params
      return documentId
    },
    isMobileDevice() {
      return isMobile
    },
    transparent() {
      return !this.fullscreenEnable ? 1 : 0.9
    },
    typeContentComponent() {
      let result = this.type.charAt(0).toUpperCase() + this.type.slice(1)
      if (result === 'Create') {
        result = 'Edit'
      }
      return result + 'Type'
    },
    tabs() {
      let result = []
      
      if (this.type === 'preview') {
        result = [
          {
            id: 'card',
            label: 'Card'
          },
          {
            id: 'details',
            label: 'Details'
          }
        ]
      }

      return result 
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onClickOutside() {
      return
    },
    onClosedFromPreview() {
      this.show = false
    },
    onOpenedFromPreview() {
      
    },
    async loadItem() {
      this.loading = true
      const doc = await this.$store.dispatch('getDocumentById', this.itemId)
      if (!doc) return
      const prepared = await this.loadElementData(doc.data())
      this.$store.commit('setItemDialogItem', prepared)
      this.$nextTick(() => {
        this.show = true
        this.loading = false
      })
    },
    onClickToActual() {
      const finded = this.$store.state.linearZoomArray.findIndex(i => i === 1)
      if (finded !== -1) {
        this.sliderModel = finded + 1
      }
    },
    setGlobalScroll(val) {
      const { body, documentElement } = document
      const methodName = val ? 'remove' : 'add'
      body.classList[methodName]('hide-scroll')
      documentElement.classList[methodName]('hide-scroll')
    },
    onClickCancel() {
      if (this.prevType) {
        this.$emit('changeType', this.prevType)
      } else {
        console.log(this.model)
        this.model = false
      }
    },
    onClickSuccess() {
      const { content } = this.$refs
      this.$emit('success', (content.tempFiles || []).map(f => f[0]))
    },
    copyCardContent() {
      console.log("copyCardContent -> copyCardContent")
    },
    copyCardLink() {
      this.inCopyProcess = true
      this.$nextTick(() => {
        const { copyInput } = this.$refs
        copyInput.value = window.location.origin + `/documents/${this.itemId}`
        copyInput.focus()
        copyInput.select()
        document.execCommand('copy')
        
        this.inCopyProcess = false
      })
    },
    addCardToFavorite() {
      this.$emit('changeFavorite', {
        fbId: this.itemId,
        item: this.item
      })
    },
    onEmitAction(action) {
      const { id } = action
      switch (id) {
        case 'copy':
          this.copyCardContent()
          break
        case 'edit':
          this.$emit('changeType', 'edit')
          break
        case 'link':
          this.copyCardLink()
          break
        case 'favorite':
          this.addCardToFavorite()
          break
      }
    },
    onChangeCommentsState() {
      this.showComments = !this.showComments
    },
    clearData() {
      this.show = false,
      this.inCopyProcess = false,
      this.brokenIds = [],
      this.sliderModel = 100,
      this.prevType = null,
      this.showComments = false,
      this.toolbarTabModel = 0
    }
  },
  watch: {
    show(to) {
      !to && this.clearData()
      !to && this.$emit('input', false)
      !to && this.$emit('close')
      if (!to) {
        this.model = false
      }
    },
    model(to) {
      this.show = to
    },
    type(to, from) {
      if (!to) return
      this.prevType = from
    }
  }
}
</script>
<style lang="scss">
.v-dialog.item-dialog {
  max-height: calc(100% - 80px);
  height: calc(100% - 80px);
  overflow: hidden !important;

  &.v-dialog--fullscreen {
    .item-dialog__bar {
      position: fixed;
      left: 0px;
      top: 0px;
      height: 100%;
    }
  }

  &.v-dialog--fullscreen,
  &.transparent-toolbars {
    max-height: 100%;
    height: 100%;

    .item-dialog-toolbar,
    .item-dialog__footer {
      position: fixed;
      left: 0px;
      width: 100%;
      z-index: 2;
    }

    .item-dialog__content {
      padding-top: calc(5vh + 47px);
    }

    &.transparent-toolbars {
      max-height: calc(100% - 80px);
      height: calc(100% - 80px);
      overflow: hidden !important;

      .item-dialog-toolbar,
      .item-dialog__footer {
        position: absolute;
      }
    }

    .item-dialog-toolbar {
      top: 0px;
      //background: linear-gradient(to bottom,rgba(255,255,255,.9) 0%,transparent 100%) !important;
    }

    .item-dialog__footer {
      bottom: 0px;
      //background: linear-gradient(to bottom,transparent 0%,rgba(255, 255,255,0.9) 100%) !important;
      
    }

    .item-dialog__bar {
      padding-top: 80px;
      z-index: 3;
    }

    &.theme--dark {
      .item-dialog-toolbar {
        //background: linear-gradient(to bottom,rgba(0,0,0,0.6) 0%,transparent 100%) !important;
      }
      .item-dialog__footer {
        //background: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,0.6) 100%) !important;
      }
    }
  }
}

.item-dialog {
  overflow: hidden;
  &__inner {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    z-index: 1;
  }

  &__content {
    overflow-y: auto;
    padding: 5vh 0px 0px;
  }

  &.v-dialog--fullscreen,
  &.transparent-toolbars {
    .item-dialog__content {
      padding-bottom: 0vh;
    }
  }

  

  &.image-type &__content {
    padding-bottom: 0px;
  }

  &__bar {
    margin: 0 0 0 16px;
  }

  &__bar {
    padding: 16px 0 0;
  }

  &__footer {
    padding: 8px 16px;
    font-size: 13px;
  }
}
</style>