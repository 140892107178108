<template>
  <sl-vue-tree
    v-model="tempTree"
    :allow-multiselect="true"
    @select="nodeSelected"
    @drop="nodeDropped"
    @toggle="nodeToggled"
    class="tree"
    ref="tree"
    v-if="tempTree"
  >
    <template slot="title" slot-scope="{ node }">
      <v-list-item
        class="d-flex px-0 align-items-center tree-item"
        @contextmenu.prevent="(e) => showFoldersMenu(e, node.data)"
        :input-value="node.data.docId === $store.getters.activeFolder[0]"
        color="primary"
        :ripple="false"
        @click.stop="() => onSelect(node)"
      >
        <v-list-item-icon
          style="margin-right: 8px !important;"
          class="pt-0 pl-2 align-self-center"
        >
          <ionic-icon
            size="24px"
            :open="node.isExpanded"
            :icon="node.data.info.icon"
            :data-folder-id="node.data.id"
            :style="{
              display: 'flex !important',
              alignSelf: 'center !important',
              color:
                node.data.info.icon && node.data.info.icon.color
                  ? node.data.info.icon.color
                  : 'var(--main-dark-color)',
            }"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ node.data.info.title }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </template>
      <template slot="toggle" slot-scope="{ node }">
        <span>
          <v-icon v-if="node.children.length">
            {{ node.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
          </v-icon>
        </span>
      </template>
      <template slot="draginfo">
        <v-list-item
          v-for="selectedNode in selectedNodes"
          :key="`selected-node-${selectedNode.data.docId}`"
        >
          <v-list-item-icon>
            <ionic-icon
              size="24px"
              :open="selectedNode.isExpanded"
              :icon="selectedNode.data.info.icon"
              :data-folder-id="selectedNode.data.id"
              :style="{
                display: 'flex !important',
                alignSelf: 'center !important',
                color:
                  selectedNode.data.info.icon && selectedNode.data.info.icon.color
                    ? selectedNode.data.info.icon.color
                    : 'var(--main-dark-color)',
              }"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ selectedNode.data.info.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
</sl-vue-tree>
</template>
<script>
import SlVueTree from 'sl-vue-tree'
import IonicIcon from "@/components/ionic-icons/icon";

export default {
  props: ['value', 'showFoldersMenu'],
  name: 'Tree',
  data: () => ({
    inited: false,
    needUpdate: true,
    tempTree: null,
    selectedNodesTitle: null,
    selectedNodes: [],
    toggledNode: null
  }),
  components: {
    SlVueTree,
    IonicIcon
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    nodeSelected(nodes) {
      this.selectedNodes = nodes
    },
    nodeDropped(nodes, position) {
      const { node: to, placement } = position
      console.log('Dropped: ', nodes, to, placement)
    },
    nodeToggled(node) {
      this.toggledNode = node
    },
    onSelect(node) {
      const { data } = node
      
      this.$emit('click:item', data)
    }
  },
  watch: {
    model(to) {
      this.tempTree = to
    },
    toggledNode() {
      this.needUpdate = false

      this.$nextTick(() => {
        this.needUpdate = true
      })
    },
    selectedNodes() {
      this.needUpdate = false

      this.$nextTick(() => {
        this.needUpdate = true
      })
    }
  }
}
</script>
<style lang="scss">
.sl-vue-tree {
    position: relative;
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
    overflow: hidden;
    position: relative;
    padding-bottom: 4px;
}

.sl-vue-tree-node-list {
    position: relative;
    display: flex;
    flex-direction: row;
}

.sl-vue-tree-node-item {
    position: relative;
    display: flex;
    flex-direction: row;
}
// .sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
//   outline: 1px solid rgba(100, 100, 255, 0.5);
// }

.sl-vue-tree-gap {
    width: 20px;
    min-height: 1px;

}

.sl-vue-tree-sidebar {
    margin-left: auto;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px dashed #1976d2;
  height: 1px;
  width: 100%;
}

.sl-vue-tree-drag-info {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    opacity: 0.5;
    margin-left: 20px;
    margin-bottom: 20px;
    padding: 5px 10px;
}

.sl-vue-tree-node-item {
  padding: 0px 16px;
}

.sl-vue-tree-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.v-list-item.tree-item {
  padding: 0 0px;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid red;
  height: 1px;
  width: 100%;
}

.sl-vue-tree {
  position: relative;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.sl-vue-tree.sl-vue-tree-root {
    color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
    overflow: hidden;
    position: relative;
    padding-bottom: 4px;
}

.sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
    color: red;
}

.sl-vue-tree-node-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  padding-right: 0px;
  line-height: 28px;
}

.sl-vue-tree-node-item .tree-item {
  border: 1px dashed transparent;
  transition: border-color 0.3s;
}


.sl-vue-tree-node-item.sl-vue-tree-cursor-inside .tree-item {
  border: 1px dashed #1976d2;
}

.sl-vue-tree-gap {
  width: 24px;
  min-height: 1px;
  margin-left: 8px;
  flex-shrink: 0;
}

.sl-vue-tree-toggle {
  display: inline-block;
  text-align: left;
  cursor: pointer;
  width: 24px;
  margin-left: 8px;
  flex-shrink: 0;
}

.sl-vue-tree-sidebar {
    margin-left: auto;
}

.sl-vue-tree-cursor {
    position: absolute;
    border: 1px dashed #1976d2;
    height: 1px;
    width: 100%;
}

.sl-vue-tree-drag-info {
    position: absolute;
    opacity: 0.5;
    margin-left: 20px;
    padding: 5px 10px;
}
</style>