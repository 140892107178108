<template>
  <v-card
    :ripple="false"
    :max-width="$store.getters.columnWidth"
    width="100%"
    flat
    height="54"
    class="folders-list__item my-2"
    style="border: none !important;"
    :dark="!!itemData.info.color"
    :id="`item-folder-${itemData.docId}`"
    color="transparent"
    @click="$emit('goToFolder', itemData.docId)"
    @drop.prevent="e => $emit('dropTo', { e, item: itemData })"
    @contextmenu.prevent.stop="e => $parent.$emit('clickMenu', {
      e,
      item: itemData
    })"
  >
    <v-toolbar
      :dark="!!itemData.info.color"
      :color="itemData.info.color ? hexToRgba(itemData.info.color) : 'rgba(255, 255, 255, 0.1)'"
      flat
      height="54"
      :style="{
        border: `1px solid ${itemData.info.color ? hexToRgba(itemData.info.color) : 'rgba(0, 0, 0, 0.2)'}`,
        boxSizing: 'border-box',
      }"
    >
      <ionic-icon 
        :icon="currentEditableFolderIcon"
        size="24px"
        :style="{
          color: currentEditableFolderIcon && currentEditableFolderIcon.color ? currentEditableFolderIcon.color : 'var(--main-dark-color)'
        }"
      />
      <span class="ml-3 folders-list__item-title no-draggable font-weight-medium">
        {{ itemData.info.title }}
      </span>
      
      <v-spacer />
      <v-icon
        @click.stop="e => $parent.$emit('clickMenu', {
          e,
          item: itemData
        })"
      >
        mdi-dots-vertical
      </v-icon>
    </v-toolbar>
  </v-card>
</template>
<script>
import IonicIcon from '@/components/ionic-icons/icon'

export default {
  props: {
    itemIndex: {},
    dragovered: {},
    itemData: {
      type: Object,
      required: true
    }
  },
  name: 'FoldersItem',
  components: {
    IonicIcon
  },
  computed: {
    countCols() {
      const cols = parseInt(12 / this.$store.getters.columnCount) || this.getDefaultColsValue()
      return cols
    },
    currentEditableFolderIcon() {
      const { info = {} } = this.itemData
      const { icon } = info
      return icon ? icon : {
        name: 'folder-outline',
        type: 'ionic'
      }
    }
  },
  methods: {
    hexToRgba(hex, opacity = 0.6) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      const r = parseInt(result[1], 16)
      const g = parseInt(result[2], 16)
      const b = parseInt(result[3], 16)
      return result ? `rgba(${r}, ${g}, ${b}, ${opacity})` : null;
    },
    getDefaultColsValue() {
      if (this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg) return 2
      if (this.$vuetify.breakpoint.md) return 3
      if (this.$vuetify.breakpoint.sm) return 4 

      return 4
    },
    onEdit() {
      this.$store.commit('setEditFolderDialogModel', true)
      this.$store.commit('setEditFolderDialogItem', this.itemData)
    },
  }
}
</script>
<style lang="scss">
.folders-list__col {
  padding: 0px 8px 8px !important;
}

.folders-list__item {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0,0,0,.2)!important;
  letter-spacing: 0.9px;
  .v-btn {
    opacity: 0;
  }

  &:hover {
    .v-btn {
      opacity: 1;
    }
  }
  &-title {
    font-weight: 500;
    font-size: 13px !important;
  }

  .v-toolbar__content {
    padding-right: 6px;
  }
}

@media screen and (max-width: 600px) {
  .folders-list__col {
    padding: 0px 10px 10px !important;
  }
}

.folders-list__item {
  position: relative;
  border-radius: $border-radius-folder !important;
  &-title {
    font-size: 13px;
    font-weight: 500;
  }
}
</style>