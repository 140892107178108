import "@/fb"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify'
import i18n from '@/i18n/vuetify'
import Typograf from 'typograf'
// import aos from 'aos/dist/aos'
import 'aos/dist/aos.css'
import VueQuillEditor from 'vue-quill-editor'
import '@/sass/global.scss'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import './registerServiceWorker'

import * as Sentry from '@sentry/browser'
import { Integrations } from "@sentry/tracing"

import VueLazyload from 'vue-lazyload'
import VueAos from 'vue-aos'
Vue.use(VueAos)

Vue.use(VueLazyload, {
    // set observer to true
    observer: true,

    // optional
    observerOptions: {
      rootMargin: '0px',
      threshold: 0.1
    }
})

const { NODE_ENV } = process.env
if (NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://3543952c09184bb0ad5289ea59cc070f@o86055.ingest.sentry.io/5553616",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

import vueShortkey from 'vue-shortkey'
Vue.use(vueShortkey, { prevent: ['input', 'textarea'] })
import VueSnip from 'vue-snip'

Vue.use(VueSnip)

Vue.use(VueQuillEditor, /* { default global options } */)

window.tp = new Typograf({locale: ['ru', 'en-US']})

Vue.config.productionTip = false

// aos.init({})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
