<template>
  <div
    class="breadcrumbs-list__item d-flex align-center no-draggable"
    :class="{'align-center': isMobileDevice}"
    @click="goBack"
  >
    <v-icon
      v-if="isMobileDevice && index > 0"
      :size="isMobileDevice ? 36: 27"
      :class="[isMobileDevice ? 'pr-2' : '']"
    >
      mdi-chevron-left
    </v-icon>
    <div
      class="breadcrumbs-list__item-title no-draggable"
      @click="$emit('goToFolder', itemData.docId)"
    >
      {{ itemData.title || itemData.info && itemData.info.title }}
    </div>
    <div
      v-if="chevron"
      class="breadcrumbs-list__item-arrow px-1"
    >
      <v-icon
        color="rgba(0, 0, 0, 0.12)"
      >
        mdi-chevron-right
      </v-icon>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMobileDevice: {
      type: Boolean
    },
    chevron: {
      type: Boolean,
      default: false
    },
    itemData: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    }
  },
  name: 'BreadcrumbsItem',
  methods: {
    goBack() {
      if (!this.isMobileDevice || this.index <= 0) return false
      this.$emit('goBack', this.index - 1)
    }
  }
}
</script>
<style lang="scss">
.breadcrumbs-list__item {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  &-title {
    cursor: pointer;
  }

  &-title, &-arrow {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &-title:hover {
    color: #1976d2;
  }

  &.active &-title {
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>