<template>
  <span
    class="ionic-icon d-inline-flex align-center justify-center"
  >
    <ion-icon
      :style="{
        color: 'inherit',
        fontSize: size
      }"
      :name="!icon ? 'folder-outline' : icon.name"
      v-bind="$attrs"
    />
  </span>
</template>
<script>
export default {
  props: {
    size: {},
    icon: {
      required: true
    }
  },
  name: 'IonicIcon',
  data: () => ({
    component: null,
    loaded: false
  }),
  mounted() {
    this.loaded = true
  }
}
</script>
<style lang="scss">

</style>