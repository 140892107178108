import store from '@/store'
import Vue from 'vue'

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app"

import "firebase/analytics"

// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

const firebaseConfig = {
  apiKey: "AIzaSyAYBUrrWmzEf2ePvjkfjZCHlxzzGB2r9MI",
  authDomain: "inspirationlibrary.app",
  databaseURL: "https://inspiration-library-app.firebaseio.com",
  projectId: "inspiration-library-app",
  storageBucket: "inspiration-library-app.appspot.com",
  messagingSenderId: "472758566248",
  appId: "1:472758566248:web:433782ff9bfbccf6f1aadd",
  measurementId: "G-YH8B18EV5L"
}

firebase.initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  // setTimeout(async () => {
  //   const { data } = await firebase.functions().httpsCallable('getAllUsers')()
  //   Promise.all(data.map(u => {
  //     const { uid: userId } = u
  //     let lib = new Library({ userId })
  //     store.dispatch('createLibrary', lib)
  //   }))
  // }, 3000)

  // firebase.functions().useFunctionsEmulator('http://localhost:3000')
}

export const storage = firebase.storage()
export const db = firebase.firestore()

// STORAGE

export const uploadProcessCb = (snapshot, documentId, uploadData, addToWidget, restData, withThumbnail = false, isThumbnail = false) => {
  const { totalBytes, bytesTransferred, state: currentState } = snapshot
  let progress = (bytesTransferred / totalBytes) * (withThumbnail ? 50 : 100)
  let state = currentState
  
  // Для тумбнейлов добавлять к прогрессу

  if (isThumbnail) {
    const allFiles = store.state.listFilesInUploadProcess
    const current = allFiles[documentId]
    if (current) {
      progress += current.progress
      console.log(progress)
    }
  }

  const toUploadArrayData = {
    ...uploadData,
    ...restData,
    progress,
    totalBytes,
    bytesTransferred,
    state,
  }

  addToWidget && store.commit('addListFilesInUploadProcess', toUploadArrayData)

  switch(snapshot.state) {
    case firebase.storage.TaskEvent.PAUSED:
      console.log('Upload is paused')
      break
    case firebase.storage.TaskEvent.RUNNING:
      console.log('Upload is running')
      break
  }
}

export const uploadErrorCb = (error, uploadData, addToWidget) => {
  switch (error.code) {
    case 'storage/unauthorized':
      console.log(`User doesn't have permission to access the object`)
      break
    case 'storage/canceled':
      console.log(`User canceled the upload`)
      break
    case 'storage/unknown':
      console.log(`Unknown error occurred, inspect error.serverResponse`)
      break
  }

  if (addToWidget) {
    const { documentId } = uploadData
    const splited = error.code.split('/')
    if (store.state.listFilesInUploadProcess[documentId]) {
      store.state.listFilesInUploadProcess[documentId].state = splited[splited.length - 1]
    }
  }
}

export const uploadSucessCb = (uploadTask, docId, path, cb, addToWidget, restData, withThumbnail, isThumbnail) => {
  const { fullPath: original } = uploadTask.snapshot.ref
  const { bytesTransferred, totalBytes, state } = uploadTask.snapshot
  const finalData = {
    path,
    documentId: docId,
    bytesTransferred,
    totalBytes,
    state: withThumbnail && isThumbnail ? state : 'running',
    progress: withThumbnail && isThumbnail ? 100 : 50,
    ...restData
  }

  addToWidget && store.commit('addListFilesInUploadProcess', finalData)
  cb && cb(original)
}

export const addToStorage = (path, file, meta = {}, documentId, addToWidget = true, docData) => {
  const splited = path.split('/')
  const isThumbnail = splited[splited.length - 1].indexOf('_thumb') !== -1
  const withThumbnail = true
  const progress = withThumbnail && isThumbnail ? 50 : 0
  console.log(isThumbnail)

  let firstData = {
    path,
    documentId,
    state: 'running',
    progress,
    totalBytes: 0,
    bytesTransferred: 0
  }
  let restData = {}

  if (addToWidget && docData) {
    const { folderId = null, type, content } = docData
    restData = {
      ...restData,
      folderId,
      type,
      content
    }
    firstData = {
      ...firstData,
      ...restData,
    }
  }

  addToWidget && store.commit('setUploadModel', true)
  addToWidget && store.commit('addListFilesInUploadProcess', firstData)

  return new Promise((res) => {
    const ref = storage.ref(path)
    const uploadTask = ref.put(file)
    meta
    const dataForUpload = {
      path,
      documentId
    }

    restData.uploadTask = uploadTask
    
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, 
      snapshot => uploadProcessCb(snapshot, documentId, dataForUpload, addToWidget, restData, withThumbnail, isThumbnail),
      error => uploadErrorCb(error, dataForUpload, addToWidget, restData, withThumbnail, isThumbnail),
      () => uploadSucessCb(uploadTask, documentId, path, res, addToWidget, restData, withThumbnail, isThumbnail))
  })
}

export const getStorageDownloadURL = path => {
  const ref = storage.ref(path)
  return ref.getDownloadURL().then(url => {
    return url
  })
    .catch(error => {
      switch (error.code) {
        case 'storage/object-not-found':
          console.log(`File doesn't exist`)
          break
        case 'storage/unauthorized':
          console.log("User doesn't have permission to access the object")
          break
        case 'storage/canceled':
          console.log("User canceled the upload")
          break
        case 'storage/unknown':
          console.log("Unknown error occurred, inspect the server response")
          break
      }

      return error
    })
}

// DB 

const getCntOnPageFromStore = () => store.state.cntOnPage
const updateSuccessCb = snapshot => {
  let result = [...store.state.files]
  let masonryResult = store.state.masonry.items
  const append = (arr, item) => {
    const [ id, data, masonryData ] = item
    const allCreatedAt = arr.map(i => i[1].createdAt)
    const { createdAt } = data
    let methodName = 'push'
    if (!createdAt ||allCreatedAt.every(cAt => cAt.toDate() < createdAt.toDate())) {
      if (masonryData) {
        masonryData.key = 0
        masonryData.groupKey = 1

        arr.forEach(arrItem => {
          if (arrItem[2].groupKey === 1) {
            arrItem[2].key += 1
          }
        })
      }
      methodName = 'unshift'
    }

    const condition = !arr.find(item => item[0] === id)

    condition && arr[methodName](item)
  }
  snapshot.docChanges().forEach(change => {
    const { type, doc } = change
    const { id } = doc
    const item = [id, doc.data()]
    const key = masonryResult.length
    const groupKey = store.state.masonry.nextGroupKey
    const index = result.findIndex(i => i[0] === id)
    const resultIndex = masonryResult.findIndex(i => i[0] === id)
    const { layout } = store.state.masonry

    const masonryData = { key, groupKey }
    let masonryItem = masonryResult[resultIndex]
    
    switch (type) {
      case 'added':
        append(result, item)
        append(masonryResult, [...item, masonryData])
        break
      case 'modified':
        if (index !== -1 && result[index]) {
          result[index] = [id, change.doc.data()]
        }

        if (resultIndex !== -1 && masonryItem) {
          Vue.set(masonryResult, resultIndex, [masonryItem[0], {
            ...masonryItem[1],
            ...doc.data()
          }, masonryItem[2]])
          layout && layout.updateItems()
        }

        break
      case 'removed':
        result.splice(result.findIndex(item => item[0] === id), 1)
        masonryResult.splice(masonryResult.findIndex(item => item[0] === id), 1)
        break
    }
  })

  store.commit('setFiles', result)
}

const updateErrorCb = err => {
  console.log(err)
}

/* --- QUERIES --- */

export const getUserSubscription = (userId = store.state.user.uid) => {
  const subscriptionsRef = db.collection('subscriptions')
  return subscriptionsRef
    .where('userId', '==', userId)
    .limit(1)
}

export const createUserSubscription = (userId = store.state.user.uid) => {
  const subscriptionsRef = db.collection('subscriptions')
  const subscription = {
    userId,
    subscriptionId: 'basic',
    addons: [],
    history: []
  }

  return subscriptionsRef.add(subscription)
    .then(() => {
      return subscription
    })
}

export const getUserPlan = subscriptionId => {
  const plansRef = db.collection('plans')
  return plansRef
    .where('subscriptionId', '==', subscriptionId)
    .limit(1)
}

// Db library queries 

export const getAllLibrariesQuery = userId => {
  const ref = db.collection('libraries')
  return ref
    .where('userId', '==', userId)
    .orderBy("createdAt", "desc")
}

export const getAllUserDocumentsQuery = () => {
  const documentsRef = db.collection('documents')
  return documentsRef
}

export const getImageQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('type', '==', 'image')
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getImageQueryAfter = userId => {
  return getImageQuery(userId).startAfter(store.state.lastItem)
}

export const getVideoQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('type', '==', 'video')
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getVideoQueryAfter = userId => {
  return getVideoQuery(userId).startAfter(store.state.lastItem)
}

export const getNoteQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('type', '==', 'note')
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getNoteQueryAfter = userId => {
  return getNoteQuery(userId).startAfter(store.state.lastItem)
}

export const getQuoteQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('type', '==', 'quote')
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getQuoteQueryAfter = userId => {
  return getQuoteQuery(userId).startAfter(store.state.lastItem)
}

// Db service queries

export const getPartsQuery = docId => {
  const documentsRef = db.collection('documents')
  const { uid: userId } = store.state.user
  return documentsRef
    .where('userId', '==', userId)
    .where('parentId', '==', docId)
    .orderBy("createdAt", "desc")
}

export const getInboxQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('folderId', '==', null)
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getInboxQueryAfter = userId => {
  return getInboxQuery(userId).startAfter(store.state.lastItem)
}

export const getHomeQuery =userId =>{
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('folderId', '==', null)
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy('createdAt', 'desc')
    .limit(getCntOnPageFromStore())
}

export const getHomeQueryAfter = userId => {
  return getHomeQuery(userId).startAfter(store.state.lastItem)
}

export const getRecentQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('deleted', '==', false)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getRecentQueryAfter = userId => {
  return getRecentQuery(userId).startAfter(store.state.lastItem)
}

export const getStarredQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('deleted', '==', false)
    .where('favorite', '==', true)
    .where('parentId', '==', null)
    .orderBy('createdAt', 'desc')
    .limit(getCntOnPageFromStore())
}

export const getDocById = docId => {
  console.log(docId, store.state.user)
}

export const getStarredQueryAfter = userId => {
  return getStarredQuery(userId).startAfter(store.state.lastItem)
}

export const getTrashQuery = userId => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('deleted', '==', true)
    .where('parentId', '==', null)
    .orderBy("deletedAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getTrashQueryAfter = userId => {
  return getTrashQuery(userId).startAfter(store.state.lastItem)
}

export const getFolderByIdQuery = (userId, folderId) => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('deleted', '==', false)
    .where('folderId', '==', folderId)
    .where('parentId', '==', null)
    .orderBy("createdAt", "desc")
    .limit(getCntOnPageFromStore())
}

export const getFolderByIdQueryAfter = (userId, folderId) => {
  return getFolderByIdQuery(userId, folderId).startAfter(store.state.lastItem)
}

export const getTreeQuery = userId => {
  const { activeLibrary } = store.getters
  if (!activeLibrary) return
  return db.collection("folders")
    .where("userId", "==", userId)
    .where('libraryId', '==', activeLibrary[0])
    .orderBy('createdAt')
}

export const getFolderById = (userId, folderId) => {
  const ref = db.collection('folders')
  return ref.doc(folderId).get()
}

export const getFoldersByParentId = (userId, parentId) => {
  const libraryId = store.getters.activeLibrary[0]
  const ref = db.collection('folders')
  return ref
    .where('userId', '==', userId)
    .where('libraryId', '==', libraryId)
    .where('parentId', '==', parentId)
    .orderBy('createdAt')
}

export const getFilesInFolderQuery = (userId, folderId) => {
  const { activeLibrary } = store.getters
  const documentsRef = db.collection('documents')
  return documentsRef
    .where('userId', '==', userId)
    .where('libraryId', '==', activeLibrary[0])
    .where('deleted', '==', false)
    .where('folderId', '==', folderId)
    .where('parentId', '==', null)
}

/* --- QUERIES --- */

/* --- FUNCTIONS --- */

export const folderFunctions = {
  addFolder(folder) {
    return db.collection('folders').add(folder)
      .then(ref => ref)
  },
  deleteFolder(folderId) {
    return db.collection('folders').doc(folderId).delete()
  },
  updateFolder(payload) {
    return db.collection('folders').doc(payload.docId).update({
      ...payload.data
    })
      .then(() => getFolderById(store.state.user.uid, payload.docId))
  },
}

export const libraryFunctions = {
  updateLibrary(payload) {
    return db.collection('libraries').doc(payload.docId).update({
      ...payload.data
    })
  }
}

/* ---- FUNCTIONS --- */

/* --- EVENTS --- */

export const onUpdateUserSettings = docId => {
  return db.collection('users').doc(docId)
    .onSnapshot(doc => {
      const data = doc.data()
      store.commit('setUserSettings', data)
    })
}

export const onUpdateTree = docId => {
  return db.collection('folders').doc(docId)
  .onSnapshot(doc => {
    const { tree } = doc.data()
    if (!tree) return
    store.commit('setTree', tree)
  }, err => {
    console.log('Update tree: ', err)
  })
}

export const onUpdateLibrariesList = userId => {
  return new Promise(res => {
    return getAllLibrariesQuery(userId)
    .onSnapshot(snapshot => {
      let result = store.state.libraries
      snapshot.docChanges().forEach(change => {
        const { type, doc } = change
        switch (type) {
          case 'added':
            if (!result.find(f => f[0] === doc.id)) {
              result = [...result, [doc.id, doc.data()]]
            }
            break
          case 'modified':
            result = result.map(l => {
              const [ id, data ] = l
              if (id === doc.id) {
                return [id, doc.data()]
              }
              return [id, data]
            })
            break
          case 'removed':
            result = result.filter(f => f[0] !== doc.id)
            break
          default:
            break
        }
      })

      store.commit('setLibraries', result)
      const ilActiveLibrary = JSON.parse(localStorage.getItem('il-active-library'))
      const findedInList = store.getters.activeLibraryById(ilActiveLibrary)
      const currentLibrary = findedInList || result[0]

      console.log('currentLibrary: ', currentLibrary)

      store.commit('setActiveLibrary', currentLibrary)
      res()
    })
  })
}

export const onUpdateFoldersList = userId => {
  return getTreeQuery(userId)
    .onSnapshot(snapshot => {
      let result = [...store.state.folders]
      const [activeFolderId] = store.state.activeFolder
      snapshot.docChanges().forEach(change => {
        const { type, doc } = change
        switch (type) {
          case 'added':
            if (!result.find(f => f[0] === doc.id)) {
              result = [...result, [doc.id, doc.data()]]
            }
            break
          case 'modified':
            result = result.map(f => {
              const [ id, data ] = f
              if (id === doc.id) {
                return [id, doc.data()]
              }
              return [id, data]
            })
            break
          case 'removed':
            if (activeFolderId === doc.id) {
              store.commit('setActiveFolder', ['inbox'])
              store.dispatch('loadFiles', { isFirst: true, folderId: 'inbox' }, )
            }
            result = result.filter(f => f[0] !== doc.id)
            break
        }
      })

      store.commit('setFolders', result)
    })
}

// Inbox

export const onUpdateInbox = userId => {
  return getInboxQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateInboxAfter = userId => {
  return getInboxQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Folders

export const onUpdateHome = userId => {
  return getHomeQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateHomeAfter = userId => {
  return getHomeQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Recent 

export const onUpdateRecent = userId => {
  return getRecentQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateRecentAfter = userId => {
  return getRecentQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Favorite 

export const onUpdateStarred = userId => {
  return getStarredQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateStarredAfter = userId => {
  return getStarredQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Trash 

export const onUpdateTrash = userId => {
  return getTrashQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateTrashAfter = userId => {
  return getTrashQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Image 

export const onUpdateImage = userId => {
  return getImageQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateImageAfter = userId => {
  return getImageQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Video

export const onUpdateVideo = userId => {
  return getVideoQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateVideoAfter = userId => {
  return getVideoQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Text 

export const onUpdateNote = userId => {
  return getNoteQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateNoteAfter = userId => {
  return getNoteQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Quote 

export const onUpdateQuote = userId => {
  return getQuoteQuery(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateQuoteAfter = userId => {
  return getQuoteQueryAfter(userId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

// Dir 

export const onUpdateFolderById = (userId, folderId) => {
  return getFolderByIdQuery(userId, folderId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

export const onUpdateFolderByIdAfter = (userId, folderId) => {
  return getFolderByIdQueryAfter(userId, folderId)
    .onSnapshot(updateSuccessCb, updateErrorCb)
}

/* --- API --- */

export const timestamp = firebase.firestore.FieldValue.serverTimestamp

export const authUi = new firebaseui.auth.AuthUI(firebase.auth())
export const authUiStart = () => {
  authUi.start('#firebase-auth-container', {
    signInSuccessUrl: '/',
    
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          prompt: 'select_account'
        }
      }
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    tosUrl: '',
    privacyPolicyUrl: ''
  })
}

export const updateUserProfile = (userData) => {
  const user = firebase.auth().currentUser
  return user.updateProfile(userData)
}

export const auth  =  {
  auth: firebase.auth(),
  logout() {
    return firebase.auth().signOut()
    .then(() => true)
    .catch(function(error) {
      console.log(error)})
  }
}

// Functions 

export const cors = firebase.functions().httpsCallable('cors')