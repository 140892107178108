import { timestamp } from '@/fb'

const defaultScheme = {
  info: {
    title: 'Library',
    description: 'Default library',
    icon: null
  },
  schemaVersion: 1,
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  userId: null
}

export default class Library {
  constructor(scheme = defaultScheme) {
    const defaultEntries = Object.entries(defaultScheme)
    const entries = Object.entries(scheme)
    
    const setProp = ([key, val]) => {
      this[key] = val
    }

    defaultEntries.forEach(setProp)
    entries.forEach(setProp)

    this.createdAt = timestamp()   
    this.id = Library.getUniqId()
  }

  static getUniqId() {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let autoId = ''
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length))
    }
  
    return autoId
  }
}