import { timestamp } from '@/fb'

export default class Folder {
  constructor({ userId }) {
    this.userId = userId
    this.createdAt = timestamp()
    this.updatedAt = timestamp()
    this.deletedAt = null
    this.deleted = false
    this.parentId = null
    this.info = {
      title: '',
      color: null,
      description: ''
    }
    this.schemaVersion = 1
  }
}