<template>
  <!--
    :data-aos="!disabledAos ? 'fade-up' : null "
    data-aos-once="true"
    :data-aos-id="`aos-item-${index}`"
   -->
  <v-card
    class="masonry-item__card"
    :class="[
      `masonry-item-${item.type}`,
      isMobileDevice ? 'masonry-item__mobile' : ''
    ]"
    :data-selected="selected"
    :fade-up-ended="disabledAos"
    ref="masonry-item"
    v-vue-aos="aosOptions"
    :data-dragentered="$store.getters.currentEnteredElement[0] === index"
    :color="getColorForType(item.type)"
    elevation="1"
    draggable
    @contextmenu.stop="e => showDesktopMenu(e, item, index, parts)"
    @dragstart="onDragStart"
    @touchstart="e => longPress(e, item, index, parts)"
    @touchend="cancelLongPress"
    @dragend="onDragEnd"
    @dragleave="onDragLeave"
    @touchmove="longPressMove"
    @dragenter="e => onDragEnter(e, item)"
    :min-height="height"
    :width="$store.getters.columnWidth"
    :ripple="false"
    @transitionend="onAnimationEnd"
    fixed
    @click="onClick"
  >

    <component
      :height="height"
      :loading="loading"
      :is="`${itemType}Loader`"
      class="d-flex flex-column"
      style="border-radius: inherit;"
    >
      <div
        class="black white--text d-flex align-center justify-center"
        v-if="state.status === 'error'"
        style="min-height: 200px;"
      >
        Сломанный элемент!
      </div>
      <template
        v-else
      >
        <v-icon
          size="18"
          style="position: absolute; left: 5px; top: 5px; z-index: 10;"
          v-if="itemType === 'multipart'"
        >
          mdi-image-multiple
        </v-icon>
        <component
          :is="`${itemType}Type`"
          :item-id="index"
          :item="item"
          :loaded="resourceLoaded"
          :height="height"
          :parts="parts"
          class="masonry-item-type d-flex flex-column flex-nowrap flex-grow-1"
          :class="{
            'no-radius': item.info.title || item.info.description
          }"
          @addBrokenId="id => addBrokenIdCallback(id)"
          @removeBrokenId="id => removeBrokenIdCallback(id)"
          :broken-ids="brokenIds"
          @setError="setErrorCallback"
          @needUpdateView="onNeedUpdateView"
          :in-prepare-process="inPrepareProcess"
        />
      </template>
    </component>
  </v-card>
</template>
<script>
import { timestamp } from '@/fb'
import loadItemDataMixin from '@/mixins/loadItemData'

// Item types

const ImageType = () => import('@/components/ItemTypes/image')
const QuoteType = () => import('@/components/ItemTypes/quote')
const NoteType = () => import('@/components/ItemTypes/note')
const VideoType = () => import('@/components/ItemTypes/video')
const MultipartType = () => import('@/components/ItemTypes/multi')

// Item loaders
const ImageLoader = () => import('@/components/ItemLoaders/image')
const QuoteLoader = () => import('@/components/ItemLoaders/quote')
const NoteLoader = () => import('@/components/ItemLoaders/note')
const VideoLoader = () => import('@/components/ItemLoaders/video')
const MultipartLoader = () => import('@/components/ItemLoaders/multi')

import LongPress from 'vue-directive-long-press'
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'MasonryItem',
  mixins: [loadItemDataMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    inPrepareProcess: {
      type: Boolean,
      default: () => false
    },
    originalItem: {},
    openedMenu: {
      type: String
    },
    brokenIds: {
      type: Array,
      required: true
    },
    itemMasonry: {},
    index: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    aosOptions: { animationClass: 'fadeUp animated' },
    disabledAos: false,
    aosOnce: true,
    transition: 'scale-transition',
    state: {
      status: 'pending', // static, pending, error
      errors: []
    },
    longPressTimer: null,
    longPressDelay: 400
  }),
  components: {
    ImageType,
    QuoteType,
    NoteType,
    VideoType,
    MultipartType,

    ImageLoader,
    QuoteLoader,
    NoteLoader,
    VideoLoader,
    MultipartLoader
  },
  directives: {
    'long-press': LongPress
  },
  created() {
    this.loadElementData()
  },
  beforeDestroy() {
    this.disabledAos = true
  },
  computed: {
    selected() {
      return this.selectedModel.includes(this.index)
    },
    selectedModel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    partsLength() {
      return this.parts.length
    },
    original() {
      return this.item.content.original
    },
    thumbnail() {
      if (!this.item.thumbnail) return null
      return this.item.thumbnail.original
    },
    itemMasonryState() {
      return this.itemMasonry.state
    },
    loading() {
      return !this.resourceLoaded
    },
    height() {
      const { thumbnail, type, info } = this.item
      const aspectRatio = parseFloat(thumbnail && thumbnail.aspectRatio || 0)
      const columnWidth = parseFloat(this.$store.getters.columnWidth || 0)
      let result = type !== 'image' && type !== 'video' ? 200 : 'auto'
      if (columnWidth && aspectRatio) {
        result = columnWidth / aspectRatio
      }

      if (info.title || info.description) {
        result += 80
      }

      return result
    },
    includesInUploadList() {
      const result = this.$store.getters.listFilesInUploadProcess[this.index]
      if (result && result.state === 'success') return false
      return result
    },
    includesInListFilesIds() {
      return this.$store.getters.listFileIdsInLoadProcess.includes(this.index)
    },
    inLoadingProcess() {
      if (this.includesInListFilesIds || this.includesInUploadList) return true
      return  this.state.status === 'pending'
    },
    itemStatus() {
      return this.state.status
    },
    itemType() {
      return this.item.type
    },
    isMobileDevice() {
      return isMobile
    },
  },
  methods: {
    onClick() {
      if (this.selectedModel && this.selectedModel.length) {
        if (this.selectedModel.includes(this.index)) {
          this.selectedModel = this.selectedModel.filter(id => id !== this.index)
        } else {
          this.selectedModel = [...this.selectedModel, this.index]
        }
        return
      }
      this.$emit('tryReadItem', { item: this.item, fbId: this.index, parts: this.parts })
    },
    onAnimationEnd() {
      const item = this.$refs['masonry-item']
      const { $el } = item
      const hasClassFadeUp = $el.classList.contains('fadeUp')
      if (!hasClassFadeUp) return
      this.disabledAos = true
    }, 
    onNeedUpdateView() {
      this.$parent.updateItem(this.itemMasonry.groupKey - 1, this.itemMasonry.key)
    },
    async loadElementData() {
      this.resourceLoaded = false
      const type = this.itemType
      const self = this
      const cb = item => {
        if (item) {
          this.item.thumbnail = item.thumbnail
          this.item.content = item.content
        }

        self.resourceLoaded = true
        // this.$nextTick(() => {
        //   const { key, groupKey } = this.itemMasonry
        //   this.$store.getters['masonry/layout'] && this.$store.getters['masonry/layout'].updateItem(groupKey - 1, key)
        // })
      }
      if (type === 'image') {
        const res = await this.prepareImage()
        cb(res)
      } else if (type === 'note' || type === 'quote') {
        cb()
      } else if (type === 'multipart') {
        await this.onLoadParts()
      }
    },
    onDragStart(e) {
      this.$emit('dndStart', {e, index: this.index, item: this.item})
      this.$store.commit('setCurrentDragElement', this.originalItem)
    },
    onDragEnd() {
      this.$store.commit('setCurrentDragElement', [null, null])
    },
    onDragEnter(e) {
      e.preventDefault()
      const [ draggedId ] = this.$store.getters.currentDragElement
      if (this.item[0] === draggedId) return
      this.$store.commit('setCurrentEnteredElement', this.originalItem)
    },
    onDragLeave() {
      this.$store.commit('setCurrentEnteredElement', [null, null])
    },
    async onDrop(e) {
      e.preventDefault()
      this.$store.commit('setCurrentDragElement', [null, null])
      const { dataTransfer } = e
      const item = JSON.parse(dataTransfer.getData('il-item') || null)
      const itemId = JSON.parse(dataTransfer.getData('il-item-index') || null)
      const target = this.item
      const targetId = this.index
      if (itemId === targetId) return
      if (!item || !itemId) return
      if (item.type === 'multipart') return
      if (target.type === 'multipart') {
        await this.$store.dispatch('updateDocumentInFb', {
          item: {
            parentId: targetId,
            updatedAt: timestamp()
          },
          id: itemId
        })
        await this.onLoadParts()
        return
      }

      this.$emit('createMultipartItem', {
        target: [targetId, target],
        item: [itemId, item]
      })
    },
    setErrorCallback(errorPayload) {
      const { message } = errorPayload
      this.state.errors = [...this.state.errors, message]
      this.setState({
        status: 'error'
      })
    },
    addBrokenIdCallback(id) {
      this.setState({
        status: 'error'
      })
      this.$emit('addBrokenId', id)
    },
    removeBrokenIdCallback(id) {
      this.setState({
        status: 'static'
      })

      this.$emit('removeBrokenId', id)
    },
    showItem() {
      const show = () => {
        if (this.itemType === 'note' || this.itemType === 'quote') {
          this.setState({
            status: 'static'
          })
        } else {
          const status = this.includesInUploadList || this.includesInListFilesIds ? 'pending' : 'static'
          this.setState({ 
            status
          })
        }
      }

      show()
    },
    setState(newState) {
      this.state = {
        ...this.state,
        ...newState
      }
    },
    showDesktopMenu(e, item, index, parts) {
      e.preventDefault()
      if (this.isMobileDevice) return
      this.$emit('showContextMenu', { e, item, index, parts })
    },
    longPressCb(e, item, index, parts) {
      e.preventDefault()
      navigator.vibrate(50)
      this.$emit('showContextMenu', { e, item, index, parts })
    },
    longPressMove() {
      this.cancelLongPress()
    },
    longPress(e, item, index) {
      if (this.isMobileDevice) {
        this.longPressTimer = setTimeout(() => {
          this.cancelLongPress()
          this.longPressCb(e, item, index)
        }, this.longPressDelay)
      }
    },
    cancelLongPress() {
      if (this.isMobileDevice) {
        clearTimeout(this.longPressTimer)
      }
    },
    showContextMenu(e) {
      if (this.isMobileDevice) {
        e.preventDefault()
        return
      }
    },
    shareTextItem(item) {
      const { title, description: text} = item
      if (navigator.share) {
        navigator.share({
          title,
          text
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        alert('Нет share!')
      }
    },
    isUrl(str) {
    const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (regexp.test(str)) {
        return true;
      } else
      {
        return false;
      }
    },
    getImageUrl(index, url) {
      return url
    },
    getExecuteStr(str) {
      return window.tp.execute(str)
    },
    getColorForType(type) {
      let result

      switch (type) {
        case 'quote':
          result = 'white'
          break
        default:
          result = 'white'
          break
      }

      return result
    }
  },
  watch: {
    parts(to) {
      if (to) {
        this.resourceLoaded = true
      }
    },
    thumbnail(to) {
      if (!to) return
      this.loadElementData()
    }
  }
}
</script>
<style lang="scss">

// .fadeUp {
//   opacity: 0;
//   transition-property: opacity, transform;

//   &.animated {
//     opacity: 1;
//     transform: translate3d(0, 100px, 0);
//   }

// }

.masonry-item {
  &__card[data-dragentered] {
    border: 1px solid green;
  }

  &__card[data-selected]:after {
    content: 'Selected';
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(25, 218, 210, 0.3);
    border-radius: inherit;
  }

  &__card.v-card {
    display: flex;
    flex-flow: column nowrap;
    border-radius: $border-radius-item !important;
    opacity: 0;
    transform: translateY(100px);

    &.animated {
      opacity: 1;
      transition-property: opacity, transform;
      transition-duration: 0.5s;
      transform: translateY(0px);
    }

    .v-skeleton-loader {
      flex-grow: 1;
    }

    &[fade-up-ended] {
      opacity: 1 !important;
      transition: inherit;
    }
  }
  cursor: pointer;
  margin-top: 0px !important;

}

.masonry-item .masonry-item__card.v-card,
.masonry-content {
  border-radius: $border-radius-item !important;
}



.masonry-item-note .masonry-content {
  font-size: 4px;
}

.masonry-item-note .masonry-content div {
  pointer-events: none;
}

.card-mobile-menu .v-list-item .v-list-item__title{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
}

.masonry-item-quote {
  .masonry-item__content {
    line-height: 1.5;
    font-size: 16px !important;
  }

  .masonry-item__author {
    line-height: 1.5;
    font-size: 14px!important;
  }

  .masonry-item__source {
    font-size: 10px !important;
    font-family: Merriweather, serif;
    color: #777777;
    padding-top: 4px;
  }
}

.pswp__share-tooltip a {
  font-family: Merriweather, serif !important;
}

.masonry-item-type {
  border-radius: $border-radius-item;

  &.no-radius {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.roll__item .masonry-item-type {
  border-radius: $border-radius-item !important;
}

.masonry-item-type img {
  border-radius: inherit;
}
</style>