

let timer = null
let edgeSize = 50

export const createColor = () => {

	let hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

	let shuffle = function () {

		let currentIndex = hex.length
		let temporaryValue, randomIndex

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex)
			currentIndex -= 1

			// And swap it with the current element.
			temporaryValue = hex[currentIndex]
			hex[currentIndex] = hex[randomIndex]
			hex[randomIndex] = temporaryValue
		}

  }
  
	const hexColor = () => {

		// Create the color
		let color = '#';

		// Shuffle the hex values and append
		for (let i = 0; i < 6; i++) {
			shuffle(hex);
			color += hex[0];
		}

		return color;

	}

	// Return the color string
	return hexColor()

}

export function getContrast (hexcolor){
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1)
	}

	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex
		}).join('')
	}

	let r = parseInt(hexcolor.substr(0,2),16)
	let g = parseInt(hexcolor.substr(2,2),16)
	let b = parseInt(hexcolor.substr(4,2),16)

	// Get YIQ ratio
	let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

	// Check contrast
	return (yiq >= 128) ? 'black' : 'white'

}

export const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
  const hex = x.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}).join('')

export const getAverageRGB = (imgEl) => {

  let blockSize = 5, // only visit every 5 pixels
      defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
      canvas = document.createElement('canvas'),
      context = canvas.getContext && canvas.getContext('2d'),
      data, width, height,
      i = -4,
      length,
      rgb = {r:0,g:0,b:0},
      count = 0

  if (!context) {
      return defaultRGB
  }

  height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width

  context.drawImage(imgEl, 0, 0)

  try {
      data = context.getImageData(0, 0, width, height);
  } catch(e) {
      /* security error, img on diff domain */
      return defaultRGB
  }

  length = data.data.length

  while ( (i += blockSize * 4) < length ) {
      ++count;
      rgb.r += data.data[i]
      rgb.g += data.data[i+1]
      rgb.b += data.data[i+2]
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r/count)
  rgb.g = ~~(rgb.g/count)
  rgb.b = ~~(rgb.b/count)

  return rgb

}

export const getColor = (url = '') => {
  return new Promise((res, rej) => {
    const img = new Image()

    img.src = url
    img.id = 'tempImage'
      
    document.body.appendChild(img)
    img.onerror = err => {
      rej(err)
    }

    img.onload = function() {
      const ref = document.getElementById('tempImage')
      
      const color = Object.values(getAverageRGB(ref))

      document.body.removeChild(ref)
      res(color)
    }
  })
}

export const getPalette = (url = '') => {
  console.log(url)
}

export const validURL = str => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str)
}

export const findTreeNode = (item, id) => {
  if (item.docId === id) {
    return item
  } else if (item.children != null) {
    let i
    let result = null
    for (i = 0; result == null && i < item.children.length; i++) {
      result = findTreeNode(item.children[i], id)
    }

    return result
  }

  return null
}

export const debounce = (f, ms) => {

  let isCooldown = false

  return function() {
    if (isCooldown) return

    f.apply(this, arguments)

    isCooldown = true

    setTimeout(() => isCooldown = false, ms)
  };

}

export function handleMousemove( event ) {
  // NOTE: Much of the information here, with regard to document dimensions,
  // viewport dimensions, and window scrolling is derived from JavaScript.info.
  // I am consuming it here primarily as NOTE TO SELF.
  // --
  // Read More: https://javascript.info/size-and-scroll-window
  // --
  // CAUTION: The viewport and document dimensions can all be CACHED and then
  // recalculated on window-resize events (for the most part). I am keeping it
  // all here in the mousemove event handler to remove as many of the moving
  // parts as possible and keep the demo as simple as possible.

  // Get the viewport-relative coordinates of the mousemove event.
  var viewportX = event.clientX;
  var viewportY = event.clientY;

  // Get the viewport dimensions.
  var viewportWidth = document.documentElement.clientWidth;
  var viewportHeight = document.documentElement.clientHeight;

  // Next, we need to determine if the mouse is within the "edge" of the 
  // viewport, which may require scrolling the window. To do this, we need to
  // calculate the boundaries of the edge in the viewport (these coordinates
  // are relative to the viewport grid system).
  var edgeTop = edgeSize;
  var edgeLeft = edgeSize;
  var edgeBottom = ( viewportHeight - edgeSize );
  var edgeRight = ( viewportWidth - edgeSize );

  var isInLeftEdge = ( viewportX < edgeLeft );
  var isInRightEdge = ( viewportX > edgeRight );
  var isInTopEdge = ( viewportY < edgeTop );
  var isInBottomEdge = ( viewportY > edgeBottom );

  // If the mouse is not in the viewport edge, there's no need to calculate
  // anything else.
  if ( ! ( isInLeftEdge || isInRightEdge || isInTopEdge || isInBottomEdge ) ) {

    clearTimeout( timer );
    return;

  }

  // If we made it this far, the user's mouse is located within the edge of the
  // viewport. As such, we need to check to see if scrolling needs to be done.

  // Get the document dimensions.
  // --
  // NOTE: The various property reads here are for cross-browser compatibility
  // as outlined in the JavaScript.info site (link provided above).
  var documentWidth = Math.max(
    document.body.scrollWidth,
    document.body.offsetWidth,
    document.body.clientWidth,
    document.documentElement.scrollWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  var documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );

  // Calculate the maximum scroll offset in each direction. Since you can only
  // scroll the overflow portion of the document, the maximum represents the
  // length of the document that is NOT in the viewport.
  var maxScrollX = ( documentWidth - viewportWidth );
  var maxScrollY = ( documentHeight - viewportHeight );

  // As we examine the mousemove event, we want to adjust the window scroll in
  // immediate response to the event; but, we also want to continue adjusting
  // the window scroll if the user rests their mouse in the edge boundary. To
  // do this, we'll invoke the adjustment logic immediately. Then, we'll setup
  // a timer that continues to invoke the adjustment logic while the window can
  // still be scrolled in a particular direction.
  // --
  // NOTE: There are probably better ways to handle the ongoing animation
  // check. But, the point of this demo is really about the math logic, not so
  // much about the interval logic.
  (function checkForWindowScroll() {

    clearTimeout( timer );

    if ( adjustWindowScroll() ) {

      timer = setTimeout( checkForWindowScroll, 30 );

    }

  })();

  // Adjust the window scroll based on the user's mouse position. Returns True
  // or False depending on whether or not the window scroll was changed.
  function adjustWindowScroll() {

    // Get the current scroll position of the document.
    var currentScrollX = window.pageXOffset;
    var currentScrollY = window.pageYOffset;

    // Determine if the window can be scrolled in any particular direction.
    var canScrollUp = ( currentScrollY > 0 );
    var canScrollDown = ( currentScrollY < maxScrollY );
    var canScrollLeft = ( currentScrollX > 0 );
    var canScrollRight = ( currentScrollX < maxScrollX );

    // Since we can potentially scroll in two directions at the same time,
    // let's keep track of the next scroll, starting with the current scroll.
    // Each of these values can then be adjusted independently in the logic
    // below.
    var nextScrollX = currentScrollX;
    var nextScrollY = currentScrollY;

    // As we examine the mouse position within the edge, we want to make the
    // incremental scroll changes more "intense" the closer that the user
    // gets the viewport edge. As such, we'll calculate the percentage that
    // the user has made it "through the edge" when calculating the delta.
    // Then, that use that percentage to back-off from the "max" step value.
    var maxStep = 25;

    // Should we scroll left?
    if ( isInLeftEdge && canScrollLeft ) {

      let intensity = ( ( edgeLeft - viewportX ) / edgeSize );

      nextScrollX = ( nextScrollX - ( maxStep * intensity ) );

    // Should we scroll right?
    } else if ( isInRightEdge && canScrollRight ) {

      let intensity = ( ( viewportX - edgeRight ) / edgeSize );

      nextScrollX = ( nextScrollX + ( maxStep * intensity ) );

    }

    // Should we scroll up?
    if ( isInTopEdge && canScrollUp ) {

      let intensity = ( ( edgeTop - viewportY ) / edgeSize );

      nextScrollY = ( nextScrollY - ( maxStep * intensity ) );

    // Should we scroll down?
    } else if ( isInBottomEdge && canScrollDown ) {

      let intensity = ( ( viewportY - edgeBottom ) / edgeSize );

      nextScrollY = ( nextScrollY + ( maxStep * intensity ) );

    }

    // Sanitize invalid maximums. An invalid scroll offset won't break the
    // subsequent .scrollTo() call; however, it will make it harder to
    // determine if the .scrollTo() method should have been called in the
    // first place.
    nextScrollX = Math.max( 0, Math.min( maxScrollX, nextScrollX ) );
    nextScrollY = Math.max( 0, Math.min( maxScrollY, nextScrollY ) );

    if (
      ( nextScrollX !== currentScrollX ) ||
      ( nextScrollY !== currentScrollY )
      ) {

      window.scrollTo( nextScrollX, nextScrollY );
      return( true );

    } else {

      return( false );

    }

  }

}