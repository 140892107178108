import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import ru from '@/locales/ru.json'

const locale = navigator.language.split('-')[0]
const fallbackLocale = 'en'

const allLangs = {
  en,
  ru
}

Vue.use(VueI18n)

export default new VueI18n({
  pluralizationRules: {
    'ru': function(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  },
  locale, // set locale
  fallbackLocale,
  messages: allLangs // set locale messages,
})