import Vue from 'vue'
import { getStorageDownloadURL } from '@/fb'

export default {
  data: () => ({
    resourceLoaded: false,
    parts: null
  }),
  methods: {
    async loadElementData(payload) {
      const item = payload || this.item
      this.resourceLoaded = false
      const type = item && item.type
      const cb = data => {
        console.log(data)
        if (data) {
          item.thumbnail = data.thumbnail
          item.content = data.content
        }

        this.resourceLoaded = true
      }
      if (type === 'image') {
        const res = await this.prepareImage(item)
        cb(res)
      } else if (type === 'note' || type === 'quote') {
        cb()
      } else if (type === 'multipart') {
        await this.onLoadParts()
      }

      return item
    },
    async preparedParts(parts) {
      parts.forEach(async part => {
        let [, data] = part
        const { type } = data
        if (type === 'image') {
          data = await this.prepareImage(data)
        }
      })
      return parts
    },
    prepareImage(item) {
      const target = item || this.item
      return new Promise(res => {
        const img = new Image()
        const haveThumbnail = target.thumbnail && target.thumbnail.original
        const haveOriginal = target.content.original
        if (!haveOriginal && !haveThumbnail) return []
        Promise.all([getStorageDownloadURL(haveOriginal), getStorageDownloadURL(haveThumbnail)])
          .then(urls => {
            const [ originalUrl, thumbnailOriginalUrl ] = urls
            img.src = thumbnailOriginalUrl || originalUrl
            img.onload = function() {
              if (haveOriginal) {
                Vue.set(target.content, 'originalUrl', originalUrl)
              }

              if (haveThumbnail) {
                Vue.set(target.thumbnail, 'originalUrl', thumbnailOriginalUrl)
              }
              
              res(target)
            }
          })
      })
    },
    async loadParts(itemId) {
      const parts = await this.$store.dispatch('loadFileParts', itemId)
      return parts
    },
    async onLoadParts() {
      const parts = await this.loadParts(this.index)
      const preparedParts = await this.preparedParts(parts)
      this.parts = preparedParts
    },
  }
}