<template>
  <div 
    class="masonry-container"
    @dragover="dndOver"
    @dragleave="dndLeave"
    @dragend="dndEnd"
    @drop="dndDrop"
    @paste="dndPaste"
  >
    <template></template>
    <folders
      v-if="getActiveFolderChildrens.length"
    >
      <folders-item
        @createAction="tryCreateItem"
        v-for="(item, index) in getActiveFolderChildrens"
        :key="`folders-item-${item.id}-${index}`"
        :item-data="item"
        :item-index="index + 1"
        @goToFolder="onGoToFolder"
        @dropTo="payload => $emit('dropTo', payload)"
      />
    </folders>
    <template
        v-if="!filesInLoadProcess"
    >
      <masonry-wall
        :class="[getActiveFolderChildrens.length ? 'pt-9' : 'pt-6']"
        @append="onAppendMasonry"
        v-model="selectedModel"
        @prepend="e => $store.dispatch('masonry/onPrependMasonry', e)"
        style="margin-right: 8px;
        margin-left: 8px;"
      >
        <template
          v-for="(item, masonryIndex) in masonryItems"
        >
          <masonry-item
            :original-item="item"
            :key="`masonry-item-${item[0]}`"
            :groupKey="item[2].groupKey"
            :item-key="item[2].key"
            :item="item[1]"
            :item-masonry="item[2]"
            :index-masonry="masonryIndex"
            :in-prepare-process="item[0] === inPrepareProcessItem"
            :index="item[0]"
            :opened-menu="openedMenuModel"
            @updateOpenedMenu="val => openedMenuModel = val"
            :broken-ids="brokenIds"
            @showContextMenu="showContextMenuCallback"
            @addBrokenId="addBrokenIdCallback"
            @removeBrokenId="removeBrokenIdCallback"
            @createMultipartItem="onCreateMultipartItem"
            @tryEditItem="tryEditItem"
            @tryReadItem="tryReadItem"
            @tryShareItem="tryShareItem"
            @tryDeleteItem="tryDeleteItem"
            @tryCopyQuote="tryCopyQuote"
            @trySaveQuote="trySaveQuote"
            @dndStart="dndStart"
            v-model="selectedModel"
          />
        </template>
        <template slot="no-items-placeholder">
          <folder-is-empty-placeholder
            :folder-data="currentActiveFolderData || getCurrentFolderDataById()"
            v-if="!$store.getters.filesInLoadProcess && !masonryItems.length"
          />
        </template>
      </masonry-wall>
    </template>
    <v-bottom-sheet
      v-if="menu && isMobileDevice"
      v-model="menu"
    >
      <template
        v-for="(item, index) in getItemActions(itemForMenu)"
      >
        <v-list
          class="card-mobile-menu"
          :key="`item-menu-${index}`"
        >
          <v-list-item
            v-for="action in item.items"
            :key="`item-action-${action.name}`"
            @click="emitItemAction(action, itemForMenu, itemForMenuId, partsItemForMenu)"
          >
            <v-list-item-icon>
              <v-icon>
                {{ action.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ action.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider color="#e4e4e4" :key="`item-menu-divider-${index}`"/>
      </template>
    </v-bottom-sheet>
    <masonry-item-menu 
      v-model="menu"
      v-if="menu && !isMobileDevice"
      offset-y
      fixed
      :is-mobile-device="isMobileDevice"
      :position-x="menuX"
      :position-y="menuY"
      :open-on-click="false"
      min-width="250px"
      :menu-item="itemForMenu"
      :menu-item-id="itemForMenuId"
      @emitAction="onEmitAction"
    />

    <move-to-dialog 
    v-if="$store.getters.moveToDialogModel"
    v-model="$store.getters.moveToDialogModel"
    @createFolder="e => $emit('addFolder', e)"
    :in-add-folder-process="inAddFolderProcess"
    />
    <speed-dial-widget 
      :is-mobile-device="isMobileDevice"
      @createAction="tryCreateItem"
    />
    <drop-here-overlay 
      v-model="showDropHereOverlay"
    />
    <div id="canvas_handler" class="d-flex align-center justify-center" />
    <upload-widget 
      v-model="$store.getters.uploadModel"
      @goToFolder="onGoToFolder"
      @cancelFile="item => $store.dispatch('deleteItem',{
        id: item.documentId,
        item,
        forever: true
      })"
    />
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import { isMobile } from 'mobile-device-detect'
import MasonryWall from '@/components/masonry/wall'
import MasonryItem from '@/components/masonry/item'
import { handleMousemove, findTreeNode } from '@/api/functions'

import ImageType from '@/classes/image'
import Video from '@/classes/video'
import Quote from '@/classes/quote'
import Note from '@/classes/note'
import Multipart from '@/classes/multipart'

import Folders from '@/components/folders'
import FoldersItem from '@/components/folders/item'

/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "Clipboard" }]*/
/*global ClipboardItem*/

// Widgets

const uploadWidget = () => import('@/components/widgets/upload')
const speedDialWidget = () => import('@/components/widgets/speedDialMenu')
const masonryItemMenu = () => import('@/components/widgets/masonryItemMenu')

// Dialogs 

const moveToDialog = () => import('@/components/dialogs/moveTo')

// Overlays 

const dropHereOverlay = () => import('@/components/overlays/dropHere')

// Placeholders

const folderIsEmptyPlaceholder = () => import('@/components/placeholders/folderIsEmpty')

import { cors, timestamp } from '@/fb'
import mime from 'mime-types'
import itemDialogMixin from '@/mixins/itemDialog'

import htmlToText from 'html-to-text'

export default {
  props: {
    inAddFolderProcess: {}
  },
  name: 'Home',
  components: {
    MasonryWall,
    MasonryItem,
    Folders,
    FoldersItem,

    // Dialogs 
    moveToDialog,

    // Widgets 

    uploadWidget,
    speedDialWidget,
    masonryItemMenu,

    // Overlays

    dropHereOverlay,

    // Placeholders

    folderIsEmptyPlaceholder
  },
  mixins: [ itemDialogMixin ],
  data() {
    return {
      selectedModel: [],
      inPrepareProcessItem: false,
      dragoveredFolderId: null,
      menu: false,
      menuX: null,
      menuY: null,
      itemForMenu: null,
      partsItemForMenu: [],
      itemForMenuId: null,
      openedMenuModel: null,
      showDropHereOverlay: false,
      fieldsOrder: {
        image: ['title', 'description', 'content', 'original'],
        video: ['title', 'description', 'content' , 'original'],
        note: ['title', 'content', 'text'],
        quote: ['content', 'quote', 'author', 'source'],
        multipart: []
      },
      deleteItemForever: false,
      brokenIds: [],
      currentFbId: null,
      masonryUpdated: true,
      cardIsHovered: false,
      options: {
        width: 300,
        padding: {
          2: 6,
          default: 8
        },
      }
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      this.menu = false
    })
  },
  computed: {
    inLoadingProcess: {
      get() {
        return this.$store.getters.itemDialogLoading
      },
      set(val) {
        this.$store.commit('setItemDialogLoading', val)
      }
    },
    masonryItems() {
      const result = this.$store.getters['masonry/items']
      return result
    },
    filesInLoadProcess() {
      return this.$store.getters.filesInLoadProcess
    },
    activeRoute() {
      return this.$route
    },
    getActiveFolderChildrens() {
      if (this.currentActiveFolderData) {
        const folders = [...this.$store.getters.tree]
        const result = this.currentActiveFolderData.id === 'home' ? folders : []
        return result
      }
      const [activeFolderId] = this.$store.getters.activeFolder
      const findedNode = findTreeNode({ children: this.$store.getters.tree }, activeFolderId)
      return findedNode ? findedNode.children : []
    },
    currentActiveFolderData() {
      const [activeFolderId] = this.$store.getters.activeFolder
      const result = this.$store.getters.virtualTree.reduce((acc, item) => {
        acc = [...acc, ...item.items]
        return acc
      }, [])
      const virtualTreeIds = result.map(item => item.id)
      let data
      if (virtualTreeIds.includes(activeFolderId)) {
        data = result.find(item => item.id === activeFolderId)
      } else {
        data = null
      }

      return data
    },
    isMobileDevice() {
      return isMobile
    },
    items() {
      const [ activeFolderId ] = this.$store.getters.activeFolder
      const exclude = ['inbox', 'recent', 'trash', 'starred', 'home']
      const allVirtualFolderNames = this.$store.state.virtualTree.reduce((acc, treeItem) => {
        acc = [...acc, ...treeItem.items.map(item => item.id)]
        return acc
      }, []).filter(key => !exclude.includes(key))
      if (!allVirtualFolderNames.includes(activeFolderId)) {
        return this.allItems
      }
      const result = this.filterForObjects(this.allItems, item => item.type === activeFolderId)
      return result
    },
    calculatedDataItems() {
      const trashPredicat = item => item.deleted
      const favoritePredicat = item => !item.deleted && item.favorite
      const defaultPredicat = item => !item.deleted

      if (this.currentActiveFolderData) {
        const { id } = this.currentActiveFolderData
        switch (id) {
          case 'trash':
            this.filterForObjects(this.$store.getters.files, trashPredicat)
            break
          case 'favorite':
            this.filterForObjects(this.$store.getters.files, favoritePredicat)
            break
          default:
            this.filterForObjects(this.$store.getters.files, defaultPredicat)
            break
        }
      } else {
        this.filterForObjects(this.$store.getters.files, defaultPredicat)
      }

      return this.sortByDate(this.$store.getters.files)
    },
    allItems() {
      return Object.entries(this.calculatedDataItems)
    }
  },
  methods: {
    onEmitAction(payload) {
      const { fbId } = payload
      const { id } = payload.action
      
      if (id === 'select') {
        if (this.selectedModel.includes(fbId)) {
          this.selectedModel = this.selectedModel.filter(id => id !== fbId)
        } else {
          this.selectedModel.push(fbId)
        }

        return
      }

      this.emitItemAction(payload)
    },
    onSuccessItemDialog(items = []) {
      const type = this.itemDialogType
      switch (type) {
        case 'create':
          items && items.length ? this.multipleCreateItem(items.map(original => {
          const { type: mimeType } = original
          let type = this.typeForMime(mimeType)

          const template = this.itemDefaultTemplate(type)
          const result = {
            ...template,
            content: {
              ...template.content,
              original
            }
          }

          return result
        })) : this.createItem()
          break
        case 'edit':
          this.editItem()
          break
        case 'delete':
          this.deleteItem()
          break
      }
    },
    onClickMenu() {
      alert()
    },
    async onAppendMasonry(e) {
      this.$store.dispatch('masonry/onAppendMasonry', e)
    },
    getCurrentFolderDataById() {
      const [ id ] = this.$store.getters.activeFolder
      const findedNode = findTreeNode({ children: this.$store.getters.tree }, id)
      return findedNode
    },
    onFetchData() {
      const activeFolder = this.$store.getters.activeFolder
      const [ activeFolderId ] = activeFolder
      this.$store.dispatch('updateFiles', activeFolderId)
    },
    async onCreateMultipartItem({ target, item }) {
      const template = this.itemDefaultTemplate('multipart')
      this.inLoadingProcess = true
      const{ docId } = await this.$store.dispatch('createItem', {
        item: template
      })
      const items = [target, item]

      await Promise.all(items.map(item => {
        return this.$store.dispatch('updateDocumentInFb', {
          item: {
            parentId: docId,
            updatedAt: timestamp()
          },
          id: item[0]
        })
      }))
      this.inLoadingProcess = false
    },
    onGoToFolder(id = 'home') {
      console.log(id)
      this.$emit('goToFolder', id || 'home')
    },
    deleteAll() {
      this.$store.dispatch('clearTrash')
    },
    tryPreviewItem({ item, fbId }) {
      const { type } = item
      let target
      switch (type) {
        case 'image':
          target = document.getElementById(`masonry-item-image-${fbId}`)
          target && target.click()
          break
        default:
          this.tryReadItem({ item, fbId })
          break
      }
    },
    findAllParentsTreeNode(item) {
      let result = []
      const func = item => {
        if (!item || item.data.parentId === null) return result
        const { parentId } = item.data
        const parentNode = findTreeNode({ children: this.$store.getters.tree }, parentId)
        result.unshift(parentNode)
        func(parentNode)
      }

      func(item)

      return result
    },
    showContextMenuCallback(payload) {
      const { e, item , index, parts = []} = payload
      this.menu = false
      this.menuX = e.clientX
      this.menuY = e.clientY
      this.itemForMenu = item
      this.partsItemForMenu = parts
      this.itemForMenuId = index
      this.$nextTick(() => {
        this.menu = true
      })
    },
    emitItemAction({action, item, fbId}) {
      const { name } = action
      const [ activeFolderId ] = this.$store.getters.activeFolder
      switch (name) {
        case 'preview':
          this.tryPreviewItem({ item, fbId})
          break
        case 'share':
          this.tryShareItem({ item, fbId })
          break
        case 'favorite':
          this.tryFavoriteItem({ item, fbId })
          break
        case 'edit':
          this.tryEditItem({item, fbId})
          break
        case 'delete':
          this.tryDeleteItem({ item, fbId, forever: activeFolderId === 'trash'})
          break
        case 'copyClipboard':
          this.tryCopyQuote({item, fbId})
          break
        case 'save':
          this.trySaveQuote({ item, fbId })
          break
        default:
          break
      }
    },
    sortByDate(obj) {
      const [ activeFolderId ] = this.$store.getters.activeFolder
      const prop = activeFolderId === 'trash' ? 'deletedAt' : 'createdAt'
      return this.sortByProp(obj, prop)
    },
    sortByProp(obj, prop) {
      const entries = Object.entries(obj)
      const result = {}
      const sorted = entries.sort((a, b) => {
        const aProp = a[1][prop] && typeof a[1][prop] === 'object' ? a[1][prop].toDate() : a[1][prop]
        const bProp = b[1][prop] && typeof b[1][prop] === 'object' ? b[1][prop].toDate() : b[1][prop]

        if (aProp < bProp) {
          return 1
        }

        if (aProp > bProp ) {
          return -1
        }

        return 0
      })
      sorted.forEach(item => {
        const [id, val] = item
        result[id] = val
      })
      return result
    },
    itemDefaultTemplate(type) {
      const { uid: userId } = this.$store.getters.user
      const activeFolderId = this.$store.getters.activeFolder[0]
      const virtualFolderNames = this.$store.getters.virtualTree.reduce((acc, treeItem) => {
        acc = [...acc, ...treeItem.items.map(item => item.id)]
        return acc
      }, [])

      const folderId = virtualFolderNames.includes(activeFolderId) ? null : activeFolderId

      let result

      switch (type) {
        case 'image':
          result = new ImageType({
            userId,
            type,
            folderId
          })
          break
        case 'video':
          result = new Video({
            userId,
            type,
            folderId
          })
          break
        case 'note':
          result = new Note({
            userId,
            type,
            folderId
          })
          break
        case 'quote':
          result = new Quote({
            userId,
            type,
            folderId
          })
          break
        case 'multipart':
          result = new Multipart({
            userId,
            type,
            folderId
          })
          break
      }

      return result
    },
  loadFileFromUrl(url) {
    return cors({url})
      .then(res => {
        const { buffer, headers} = res.data
        const type = headers.find(header => header[0] === 'content-type')[1]
        const base64Url = `data:${type};base64,${buffer}`
        return fetch(base64Url)
          .then(res => res.blob())
          .then(blob => {
            const ext = mime.extension(type)
            const file = new File([blob], `temp.${ext}`, {
              type
            })
            return file
          })
      })
  },
  dndStart({ e, item, index }) {
    window.addEventListener('dragover', handleMousemove)
    e.dataTransfer.setData("il-item", JSON.stringify(item))
    e.dataTransfer.setData('il-item-index', JSON.stringify(index))
  },
  dndEnd(e) {
    window.removeEventListener('dragover', handleMousemove)
    e.preventDefault()
  },
  dndOver(e) {
    e.preventDefault()
    const { types } = e.dataTransfer
    this.showDropHereOverlay = !types.includes('il-item')
  },
  dndLeave(e) {
    e.preventDefault()
    const { screenX, screenY } = e
    if (screenX === 0 || screenY === 0) {
      this.showDropHereOverlay = false
    }
  },
  typeForMime(mime) {
    let type ='image'
    if (mime.indexOf('image') !== -1) {
      type = 'image'
    }

    return type

  },
  checkDatatransferType(dataTransfer) {
    const { types, files } = dataTransfer
    const linkReg = new RegExp('^(http|https)://', 'i')
    const isTextPlain = types.includes('text/plain') && !files.length
    const isLink = isTextPlain && linkReg.test(dataTransfer.getData('text/plain').trim())
    const result = isTextPlain ? (isLink ? 'link' : 'text') : 'file'
    return result
  },
  createItemWithoutDialog(dataTransfer) {
    const type = this.checkDatatransferType(dataTransfer)
    const noteTemplate = this.itemDefaultTemplate('note')
    let data
    switch (type) {
      case 'text':
        data = {
          ...noteTemplate,
          content: {
            ...noteTemplate.content,
            text: dataTransfer.getData('text')
          }
        }
        this.createItem(data)
        break
      case 'link':
        data = dataTransfer.getData('text')
        this.loadFileFromUrl(data)
          .then(original => {
            const { type: mimeType } = original
            let type = this.typeForMime(mimeType)
            
            const template = this.itemDefaultTemplate(type)
            const result = {
              ...template,
              content: {
                ...template.content,
                original
              }
            }

            this.createItem(result)
          })
        break
      case 'file':
        data = dataTransfer.files
        this.multipleCreateItem(Array.from(data).map(original => {
          const { type: mimeType } = original
          let type = this.typeForMime(mimeType)

          const template = this.itemDefaultTemplate(type)
          const result = {
            ...template,
            content: {
              ...template.content,
              original
            }
          }

          return result
        }))
        break
      default:
        throw new Error('Файл не поддерживается') 
    }
  },
  dndPaste(e) {
    const { clipboardData } = e
    this.createItemWithoutDialog(clipboardData)
  },
  dndDrop(e) {
    e.preventDefault()
    const { dataTransfer } = e
    if (dataTransfer.getData('il-item')) return false
    this.createItemWithoutDialog(dataTransfer)
    this.showDropHereOverlay = false
  },
  addBrokenIdCallback(id) {
    this.brokenIds = [...this.brokenIds, id]
  },
  removeBrokenIdCallback(id) {
    if (!this.brokenIds.includes(id)) return
    this.brokenIds = this.brokenIds.filter(brokenId => brokenId !== id)
  },
  filterForObjects( obj, predicate) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj,key) && predicate(obj[key])) {
          console.log()
      } else {
        delete obj[key]
      }
    }
  },
  sortByOrderType(arr, type) {
    const orderForType = this.fieldsOrder[type]
    let originalArr = [...arr]    
    let result = []

    orderForType.forEach(key => {
      let found = false
      originalArr = originalArr.filter(item => {
        if(!found && item[0] == key) {
          result.push(item)
          found = true
          return false
        } else 
          return true
        })
    })

    return result
  },
  getFieldsForItem(item) {
    if (!item) return []
    const {type} = item
    let excludeFields = [
      'id',
      'schemaVersion',
      'folderId',
      'type',
      'metaInfo',
      'userId',
      'createdAt',
      'updatedAt',
      'deletedAt',
      'parentId',
      'thumbnail',
      'deleted'
    ]
    const fields = Object.keys(item)

    const fieldsForEditable = fields.filter(field => !excludeFields.includes(field)).map(fieldName => [fieldName, item[fieldName]])
    const resultFields = fieldsForEditable.reduce((acc, field) => {
      const value = field[1]
      const valueIsObject = value instanceof Object
      if (valueIsObject) {
        acc = [...acc, ...this.sortByOrderType(Object.keys(value).map(key => [key, valueIsObject[key]]), type)]
      } else {
        acc = [...acc, field]
      }

      return acc
    }, [])

    const result = resultFields.filter(this.onlyUniq)

    return result
  },
  onlyUniq(value, index, self) {
    return self.findIndex(item => item[0] === value[0]) === index
  },
  loadImage(src) {
    return new Promise(res => {
      const image = new Image()
      
      image.onload = function() {
        res({
          width: this.width,
          height: this.height
        })
      }

      image.src = src
    })
  },
  async createItem(item) {
    console.log("🚀 ~ file: Home.vue:774 ~ createItem ~ createItem", item)
    this.inLoadingProcess = true
    this.itemDialogModel = false
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })

    const newItem = item || this.itemDialogItem
    let original = null

    if (newItem.content && newItem.content.original) {
      original = newItem.content.original
    }

    let payload = {
      item: newItem
    }

    if (newItem.type !== 'multipart') {
      if (newItem.type !== 'quote' && newItem.type !== 'note') {
        const src = URL.createObjectURL(original)
        const { width, height } = await this.loadImage(src)
        payload.width = width
        payload.height = height
      }
    }

    return this.$store.dispatch('createItem', payload)
      .then(({ docId, item, type }) => {
        this.inLoadingProcess = false
        if (type !== 'quote' && type !== 'note') {
          item
          this.$store.commit('removeListFileIdsInLoadProcess', docId)
        }
      })
  },
  multipleCreateItem(items) {
    items.forEach((item, index) => {
      setTimeout(() => {
        this.createItem(item)
      }, 1000 * index)
    })
  },
  deleteItem() {
    this.inLoadingProcess = true
    this.$store.dispatch('deleteItem', {id: this.currentFbId, item: this.itemDialogItem, forever: this.deleteItemForever})
      .then(() => {
        this.inLoadingProcess = false
        this.itemDialogModel = false
      })
  },
  editItem() {
    this.inLoadingProcess = true
    const { documentId } = this.$route.params
    this.$store.dispatch('editItem', {id: documentId || this.currentFbId, item: this.itemDialogItem})
      .then(() => {
        this.inLoadingProcess = false
        this.itemDialogType = 'preview'
      })
  },
  tryCreateItem({name: type, folderId = null }) {
    this.itemDialogType = 'create'
    this.itemDialogItem = {
      ...this.itemDefaultTemplate(type),
      type
    }
    this.itemDialogModel = true

    if (folderId) {
      this.itemDialogItem.folderId = folderId
    }
  },
  getMasonryItemElement(id) {
    return new Promise(res => {
      res(document.getElementById(`masonry-item-${id}`))
    })
  },
  getClipboardPermission() {
    return navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      if (result.state === 'granted') {
        return new Promise(res => {
          res(result.state)
        })
      }
    })
  },
  getOffset(el) {
    const rect = el.getBoundingClientRect()
    return {
      x: rect.left + window.scrollX,
      y: rect.top + window.scrollY
    }
  },
  getCanvasFromElem(elem) {
    const handler = document.getElementById('canvas_handler')
    handler.innerHTML = ''
    const copy = elem.cloneNode(true)
    handler.appendChild(copy)
    const { x, y } = this.getOffset(handler)
    const { width, height } = elem.getBoundingClientRect()
    const widthX2 = width * 2.1
    const heightX2 = height * 2.1
    handler.querySelector('div').style.width = `${widthX2}px`
    handler.querySelector('div').style.height = `${heightX2}px`
    return html2canvas(handler, {
      allowTaint: false,
      x,
      y
    })
  },
  getBlobFromCanvas(canvas) {
    return new Promise(res => {
      return canvas.toBlob(res)
    })
  },
  async tryReadItem({ item, fbId, parts = [] }) {
    this.inPrepareProcessItem = fbId
    console.log(this.inPrepareProcessItem)
    const { type } = item
    if (type === 'image') {
      const { originalUrl } = item.content
      const load = url => {
        return new Promise(res => {
          const img = new Image()
          img.src = url
          
          img.onload = e => {
            res(e)
          }
        })
      }
      
      await load(originalUrl)
    }
    this.currentFbId = fbId
    this.itemDialogType = 'preview'
    this.itemDialogModel = true
    this.itemDialogItem = {...item, id: fbId, parts }
    this.inPrepareProcessItem = false
  },
  tryFavoriteItem({ item, fbId }) {
    const currentFavoriteState = item.favorite
    this.$store.dispatch('editItem', {
      id: fbId,
      item: {
        ...item,
        favorite: !currentFavoriteState
      }
    })
      .then(item => {
        const [ , data ] = item
        this.itemDialogItem = {
          ...this.itemDialogItem,
          ...data
        }
      })
  },
  tryEditItem({ item, fbId }) {
    this.currentFbId = fbId
    this.itemDialogType = 'edit'
    this.itemDialogModel = true
    this.itemDialogItem = item
  },
  tryShareItem({ item }) {
    const { type } = item
    const { title, description: text} = item
    const { originalUrl: url } = item.content
    let data

    if (type !== 'note' && type !== 'quote') {
      data = {
        title,
        text,
        url
      }
    } else if (type === 'note') {
      const text = htmlToText.fromString(item.content.text, {
        wordWrap: 80
      })

      data = {
        title,
        text,
        url: 'https://inspirationlibrary.app'
      }
    } else {
      const text = item.content.author ? `${item.content.quote}\n— ${item.content.author}\n\r\nfrom` : `${item.content.text}\n\r\nfrom`
      data = {
        url: 'https://inspirationlibrary.app',
        text
      }
    }

    if (navigator.share) {
      navigator.share(data)
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      alert('Нет share!')
    }
  },
  tryDeleteItem({ item, fbId, forever = false }) {
    this.itemDialogType = 'delete'
    this.currentFbId = fbId
    this.deleteItemForever = forever
    this.itemDialogModel = true
    this.itemDialogItem = item
  },
  tryCopyQuote({ fbId }) {
    this.currentFbId = fbId
    this.getMasonryItemElement(this.currentFbId)
      .then(el => this.getCanvasFromElem(el))
      .then(canvas => {
        const handler = document.getElementById('canvas_handler')
        handler.innerHTML = ''

        this.getBlobFromCanvas(canvas)
          .then(blob => {
            const item = new ClipboardItem({ "image/png": blob })
            this.getClipboardPermission()
              .then(() => {
                return navigator.clipboard.write([item])
              })
          })
      })
  },
  comparer(otherArray){
    return function(current){
      return otherArray.filter(function(other){
        return other.value == current.value && other.display == current.display
      }).length == 0
    }
  },
  trySaveQuote({ fbId }) {
    this.currentFbId = fbId
    this.getMasonryItemElement(this.currentFbId)
      .then(el => this.getCanvasFromElem(el))
      .then(canvas => {
        const a = document.createElement('a')
        const href = canvas.toDataURL('image/png')

        a.setAttribute('download', 'quote')
        a.setAttribute('href', href)

        a.click()
      })
    },
  },
  watch: {
    activeRoute(to, from) {
      const { name } = to
      if (name !== 'Folders' && name !== 'VirtualFolders') return
      const { folderId = null, virtualFolderId = null } = to.params
      const resultId = folderId || virtualFolderId
      this.$emit('goToFolder', resultId)
      const { name: prevName } = from
      if (prevName === 'ItemDialog') return
      this.$store.commit('setLastItem', null)
      this.$store.commit('setFilesInLoadProcess', true)
      this.$nextTick(() => {
        this.$store.commit('setFilesInLoadProcess', false)
      })
    },
    itemDialogModel(to) {
      if (!to) return
      const id = this.itemDialogItem.id || this.currentFbId
      const path = this.itemDialogType === 'preview' || this.itemDialogType === 'edit' ? `/documents/${id}` : `/documents/${this.itemDialogType}`
      this.$router.push({
        path
      })
    }
  }

}
</script>

<style lang="scss">

.v-speed-dial.mobile-dial {
  bottom: 72px;

  &.isIOS13Device {
    @supports (bottom: env(safe-area-inset-bottom)) {
      bottom: calc(env(safe-area-inset-bottom) + 72px);
    }
  }
}

.masonry-container {
  height: 100%;
  padding: 0px 0px 0px 0px;
}
@media screen and (max-width: 600px) {
  .masonry-container {
    padding: 0px 10px;
  }
}
#canvas_handler {
  position: fixed;
  left: -10000px;
  top: -10000px;
  font-family: Merriweather, serif !important;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  q {
    quotes: "“" "”"; /* Кавычки в виде двойных угловых скобок */
  }

  .text-h6 {
    font-size: 2.2rem !important;
    line-height: 3rem !important;
  }

  * {
    font-family: Merriweather, serif !important;
  }
}

.fab-text-custom {
  position: absolute;
  right: 50px;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  backdrop-filter: blur(4px);
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  font-family: "Lora", serif;
  font-weight: 500;
  font-size: 13px;
}

.speed-dial-overlay .v-overlay__content {
  width: 100%;
  height: 100%;
}

.masonry-item:hover {
  .masonry-item__menu {
    display: block !important;
  }
}

.masonry-item {
  overflow: hidden;
  font-family: Merriweather, serif !important;

  &__title {
    font-size: 14px;
  }

  &__content {
    font-size: 12px;
  }
}
  .masonry-item__content {
    margin-bottom: 0px;
  }
.masonry-item-quote {

  .masonry-item__content, .masonry-item__author, .masonry-item-source {
    font-family: Merriweather, serif !important;
  }
}

@media screen and (max-width: 600px) {
  .masonry-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.masonry-content {
  padding: 20px;
  min-height: 80px;
  overflow: hidden;
}


img {
  object-fit: cover;
  line-height: 0;
  display: block;
  max-width: 100%;
}


</style>