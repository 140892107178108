var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"folders-list__item my-2",staticStyle:{"border":"none !important"},attrs:{"ripple":false,"max-width":_vm.$store.getters.columnWidth,"width":"100%","flat":"","height":"54","dark":!!_vm.itemData.info.color,"id":("item-folder-" + (_vm.itemData.docId)),"color":"transparent"},on:{"click":function($event){return _vm.$emit('goToFolder', _vm.itemData.docId)},"drop":function($event){$event.preventDefault();return (function (e) { return _vm.$emit('dropTo', { e: e, item: _vm.itemData }); }).apply(null, arguments)},"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return (function (e) { return _vm.$parent.$emit('clickMenu', {
    e: e,
    item: _vm.itemData
  }); }).apply(null, arguments)}}},[_c('v-toolbar',{style:({
      border: ("1px solid " + (_vm.itemData.info.color ? _vm.hexToRgba(_vm.itemData.info.color) : 'rgba(0, 0, 0, 0.2)')),
      boxSizing: 'border-box',
    }),attrs:{"dark":!!_vm.itemData.info.color,"color":_vm.itemData.info.color ? _vm.hexToRgba(_vm.itemData.info.color) : 'rgba(255, 255, 255, 0.1)',"flat":"","height":"54"}},[_c('ionic-icon',{style:({
        color: _vm.currentEditableFolderIcon && _vm.currentEditableFolderIcon.color ? _vm.currentEditableFolderIcon.color : 'var(--main-dark-color)'
      }),attrs:{"icon":_vm.currentEditableFolderIcon,"size":"24px"}}),_c('span',{staticClass:"ml-3 folders-list__item-title no-draggable font-weight-medium"},[_vm._v(" "+_vm._s(_vm.itemData.info.title)+" ")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.$parent.$emit('clickMenu', {
        e: e,
        item: _vm.itemData
      }); }).apply(null, arguments)}}},[_vm._v(" mdi-dots-vertical ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }