<template>
  <div
    class="folders-list flex-wrap pt-5"
    :class="[!!scrollBarWidth ? 'px-4' : 'px-2']"
    :style="{
      'grid-template-columns': `repeat(${$store.getters.columnCount}, ${$store.getters.columnWidth}px)`
    }"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'FoldersList',
  data: () => ({
    scrollBarWidth: 0
  }),
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize({ currentTarget }) {
      const scrollBarWidth = currentTarget.innerWidth - document.body.clientWidth 
      this.scrollBarWidth = scrollBarWidth
    }
  }
}
</script>
<style lang="scss">
.folders-list {
  display: grid;
  justify-content: space-between;
  padding: 0px 0px;
}
</style>