import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import ItemDialog from '@/components/dialogs/item'
import EmptyRouterView from '@/views/EmptyRouterView'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: EmptyRouterView,
    redirect: '/inbox',
    children: [
      {
        path: '/:virtualFolderId',
        name: 'VirtualFolders',
        component: Home
      },
      {
        path: '/folders/:folderId',
        name: 'Folders',
        component: Home
      },
      {
        path: '/documents/:documentId',
        name: 'ItemDialog',
        components: {
          default: Home,
          itemDialog: ItemDialog
        }
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
