import Document from './document'

export default class Quote extends Document {
  constructor(documentData) {
    super(documentData)
    this.content = {
      quote: '',
      author: '',
      source: ''
    }
  }
}