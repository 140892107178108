<template>
  <div
    class="masonry-wall"
  >
    <v-toolbar
      flat
      style="z-index: 10;"
      v-if="selectedModel && selectedModel.length"
      color="transparent"
    >
      <v-btn
        color="primary"
        class='mr-3'
      >
        Add to favorite {{ selectedModel.length }}
      </v-btn>
      <v-btn
        color="error"
      >
        Delete {{ selectedModel.length }}
      </v-btn>
      <v-spacer />
      <v-btn
        @click="selectedModel = []"
      >
        Clear
      </v-btn> 
    </v-toolbar>
    <grid-layout
      class="masonry-wall__grid"
      ref='ig'
      :options="options"
      :layoutOptions="layoutOptions"
      @append="onAppend"
      @layout-complete="onLayoutComplete"
      :useRecycle="false"
    >
      <slot 
        :selected="selectedModel"
      />
    </grid-layout>
    <slot name="no-items-placeholder"/>
  </div>
</template>
<script>
import { GridLayout } from "@egjs/vue-infinitegrid";

export default {
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      default: () => false
    },
    itemsCount: {
      type: Number,
      default: () => 10
    }
  },
  name: 'MasonryWall',
  created() {
    // this.workWithColumns()
    window.addEventListener('resize', this.debounce(this.workWithColumns))
  },
  mounted() {
    this.workWithColumns()
    // setTimeout(() => {
    //   if (!this.$refs.ig) return
    //   window.dispatchEvent(new Event('resize'))
    // }, 500)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounce(this.workWithColumns))
    this.$store.commit('setLastItem', null)
    this.$store.commit('masonry/setNextGroupKey', 1)
    this.$store.commit('setFiles', [])
    this.$store.commit('masonry/setItems', [])
  },
  components: {
    GridLayout
  },
  data: () => ({
    hasScroll: false,
    show: true,
    inWorkWithColumnsProcess: false,
    first: false,
    maxWidth: null,
    options: {
      transitionDuration: 0.2,
      useRecycle: false,
      horizontal: false,
      isOverflowScroll: false,
    },
    layoutOptions: {
      margin: 16,
      align: 'center'
    }
  }),
  computed: {
    selectedModel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    filesInLoadProcess() {
      return this.$store.getters.filesInLoadProcess
    }
  },
  methods: {
    onClickOutside() {
      if (this.selectedModel && this.selectedModel.length) {
        this.selectedModel = []
      }
    },
    setWidth() {
      const maxWidth = this.getMaxWidth()
      this.maxWidth = maxWidth + 'px'
    },
    getScrollbarWidth() {

      // Creating invisible container
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.overflow = 'scroll' // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
      document.body.appendChild(outer)

      // Creating inner element and placing it in the container
      const inner = document.createElement('div')
      outer.appendChild(inner)

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer)

      return scrollbarWidth

    },
    getMaxWidth() {
      const { $el } = this.$parent
      const result = $el.clientWidth - this.getScrollbarWidth()
      return result
    },
    debounce(func){
      var timer
      return function(event){
        if(timer) clearTimeout(timer)
        timer = setTimeout(func,100,event)
      }
    },
    workWithColumns() {
      if (this.inWorkWithColumnsProcess) return
      this.inWorkWithColumnsProcess = true
      
      const ig = this.$refs.ig
      if (!ig) return
      
      // const { width: bodyWidth } = document.body.getBoundingClientRect()
      // const scrollbarWidth = window.innerWidth - bodyWidth
      // scrollbarWidth
      
      let calculatedWidth = 300
      const width = ig && ig.$el.getBoundingClientRect().width - (this.hasScroll ? this.getScrollbarWidth() : 0) || 0
     
      const windowWidth = (function() {
        return window.innerWidth && document.documentElement.clientWidth ? 
        window.innerWidth : 
        window.innerWidth || 
        document.documentElement.clientWidth || 
        document.getElementsByTagName('body')[0].clientWidth
      })()


      let cntColumns = 6
    
      if (windowWidth >= 1920) {
        cntColumns = 7
      } else if (windowWidth >= 1300) {
        cntColumns = 6
      } else if (windowWidth >= 768) {
        cntColumns = 5
      } else {
        cntColumns = 2
      }

      if (width) {
        //this.layoutWidth = 1000
        calculatedWidth =  width / cntColumns - 16
        this.$store.commit('setColumnWidth', calculatedWidth)
        this.$store.commit('setColumnCount', cntColumns)
      }

      // console.log("\n")

      // console.group("workWithColumns() Data")

      // console.group("Grid")
      // console.log("Window Width: ", windowWidth)
      // console.log("Grid Width. Total: ", ig.$el.getBoundingClientRect().width)
      // console.log("Scrollbar width: ", (this.hasScroll ? this.getScrollbarWidth() : 0) || 0)   
      // console.log("Grid Width. Final: ",width)
      // console.groupEnd("Grid")
      
      // console.group("Columns")
      // console.log("Columns Count: ", cntColumns)
      // console.log("Columns Width: ", calculatedWidth)

      this.inWorkWithColumnsProcess = false
    },
    onAppend(e) {
      if (e.currentTarget.isLoading()) return
      this.$emit('append', e)
    },
    onLayoutComplete(e) {
      const { currentTarget, target } = e

      this.$store.commit('masonry/setLayout', currentTarget)
      target.forEach(item => {
        if (!item.el) return
      })

      if (!e.isLayout) {
        this.hasScroll = e.isScroll
        if (!this.$refs.ig) return
        window.dispatchEvent(new Event('resize'))
      }

      !e.isLayout && e.endLoading()
    }
  }
}
</script>
<style lang="scss">
.masonry-wall {
  height: 100%;
  min-height: 100%;
  padding-bottom: 15vh;
}
</style>