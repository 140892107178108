<template>
  <div class="folders">
    <folders-list
      @clickMenu="payload => $parent.$emit('clickMenu', payload)"
    >
      <slot />
    </folders-list>
  </div>
</template>
<script>
import FoldersList from './list'

export default {
  name: 'Folders',
  components: {
    FoldersList
  },
  methods: {
  }
}
</script>