export default {
  namespaced: true,
  state: {
    nextGroupKey: 1,
    items: [],
    layout: null,
    inited: false
  },
  getters: {
    layout: state => state.layout,
    items: state => state.items
  },
  mutations: {
    setNextGroupKey(state, val) {
      state.nextGroupKey = val
    },
    setLayout(state, val) {
      state.layout = val
    },
    setItems(state, val) {
      state.items = val
    }
  },
  actions: {
    async onAppendMasonry({ commit, dispatch, rootGetters }, e) {
      const activeFolder = rootGetters.activeFolder
      const [ activeFolderId ] = activeFolder
      if (rootGetters.lastItem) {
        const nextGroupKey = (typeof e.groupKey === "undefined" ? 0 : +e.groupKey || 0) + 1
        commit('setNextGroupKey', nextGroupKey)
        const { docs } = await dispatch('updateFiles', activeFolderId ,{ root: true })
        const lastItem = docs[docs.length - 1]
        commit('setLastItem', lastItem, { root: true })
      } else {
        if (rootGetters.lastItem === undefined) return
        await dispatch('loadFiles', {isFirst: true, folderId: activeFolderId}, { root: true })
        commit('setFilesInLoadProcess', false, { root: true })
      }
    },
    onPrependMasonry(context, e) {
      console.log(e)
    }
  }
}