<template>
  <div 
    style="min-height: 100%; height: 100%;"
    @contextmenu.prevent.stop="onContextMenu"
  >
    <router-view 
      name="itemDialog"
      :type="itemDialogType"
      :item="itemDialogItem"
      :index="$route.params.documentId"
      :dark="itemDialogDark"
      :fullscreen="isMobileDevice"
      :path-after-close="$route.path"
      v-model="itemDialogModel"
      @changeType="type => itemDialogType = type"
      @close="$emit('closeItemDialog')"
      @changeFavorite="tryFavoriteItem"
      :in-loading-process="itemDialogLoading"
      @success="onSuccessItemDialog"
    />
    <router-view 
      ref="baseView"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect'
import itemDialogMixin from '@/mixins/itemDialog'

export default {
  props: {
    activeFolder: {}
  },
  name: 'EmptyRouterView',
  mixins: [ itemDialogMixin ],
  computed: {
    isMobileDevice() {
      return isMobile
    },
    baseView() {
      return this.$refs.baseView
    }
  },
  methods: {
    onContextMenu(e) {
      const { baseView } = this.$refs
      const folderData = baseView.getCurrentFolderDataById() || null
      this.$emit('showContentContextMenu', { e, item: folderData })
    },
    tryFavoriteItem(item) {
      const { tryFavoriteItem } = this.baseView
      
      tryFavoriteItem(item)
    },
    onSuccessItemDialog(items) {
      const { onSuccessItemDialog } = this.baseView
      onSuccessItemDialog(items)
    }
  }
}
</script>