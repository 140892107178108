<template>
  <div>
    <v-app-bar
      v-if="isMobileDevice ? notApp : true"
      :app="isMobileDevice && notApp"
      :color="notApp && isMobileDevice ? 'white' : 'transparent'"
      class="breadcrumbs px-0"
      :flat="!notApp"
      :elevation="isMobileDevice ? 1 : 0"
    >
      <breadcrumbs-list>
        <slot />
      </breadcrumbs-list>
      <v-spacer />
      <slot 
        name="menu"
      />
    </v-app-bar>
  </div>
</template>
<script>
import BreadcrumbsList from './list'

export default {
  props: {
    isMobileDevice: {
      
    },
    folderData: {
      type: Object
    }
  },
  name: 'Breadcrumbs',
  components: {
    BreadcrumbsList
  },
  computed: {
    notApp() {
      if (!this.folderData) return true
      return false
    }
  },
}
</script>
<style lang="scss">
.mobile-breadcrumbs {

}

.breadcrumbs .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
</style>