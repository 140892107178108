<template>
  <div
    class="main-background"
  >
    <!-- <img 
      src="https://i.pinimg.com/originals/e2/5c/8f/e25c8f8919934536f427e380f7c185d0.jpg"
      class="main-background__image"
    /> -->
  </div>
</template>
<script>
export default {
  name: 'MainBackground'
}
</script>
<style lang="scss">
.main-background {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: white;

  &__image {
    opacity: 0.2;
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>