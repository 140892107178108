<template>
  <v-app>
    <div class="auth-background" v-if="showAuth">
      <img
        class="auth-background__img"
        :src="require('@/assets/images/auth-fon.jpg')"
      />
      <div class="auth-background__backdrop"></div>
    </div>
    <template v-if="!showAuth">
      <main-background />
      <template v-if="inited">
        <v-app-bar
          app
          class="app-bar"
          :key="`app-bar`"
          clipped-left
          :color="isMobileDevice ? 'white' : 'transparent'"
          :elevation="isMobileDevice ? 1 : 0"
          v-if="
            isMobileDevice ? (currentActiveFolderData ? true : false) : true
          "
        >
          <div class="toolbar-layer d-flex align-center pl-2 pr-4 mx-2">
            <img
              class="app-bar-logo no-draggable"
              :src="require('@/assets/logo.png')"
            />
            <v-toolbar-title
              class="no-draggable"
              style="
                font-size: 16px;
                font-family: 'Lora', serif !important;
                font-weight: 500;
              "
              v-if="!isMobileDevice && !isTabletDevice"
            >
              Inspiration Library
            </v-toolbar-title>
            <span
              style="font-size: 18px"
              v-if="
                isMobileDevice &&
                currentActiveFolderData &&
                currentActiveFolderData.title
              "
            >
              {{ currentActiveFolderData.title }}
            </span>
            <v-divider
              style="height: 24px; min-height: 24px"
              vertical
              class="mx-4 d-flex align-self-center"
            />
            <!-- TODO Library select -->
            <!-- <v-select
              :loading="inCreateLibraryProcess"
              solo
              flat
              class="library-select"
              style="max-width: 300px !important;"
              background-color="transparent"
              hide-details
              return-object
              v-model="activeLibrary"
              item-text="[1].info.title"
              :items="$store.getters.libraries"
            >
              <template v-slot:item="{item, on, attrs}">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon
                    class="d-flex align-self-center pt-0"
                    v-if="item[1].info.icon"
                  >
                    <ionic-icon 
                      :icon="item[1].info.icon"
                      size="24px"
                      :style="{
                        color: item[1].info.icon && item[1].info.icon.color ? item[1].info.icon.color : 'var(--main-dark-color)'
                      }"
                    /> 
                  </v-list-item-icon>  
                  <v-list-item-content>
                    <v-list-item-title>{{ item[1].info.title }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    class="ml-2"
                  >
                    <v-btn 
                      small
                      @click.stop="() => onClickLibraryAction(item, 'edit')"
                      icon
                    >
                      <v-icon
                        small
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action
                    class="ml-1"
                    v-if="$store.getters.libraries.length > 1"
                  >
                    <v-btn 
                      small
                      @click.stop="() => onClickLibraryAction(item, 'delete')"
                      icon
                    >
                      <v-icon
                        small
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item> 
              </template>
              <template v-slot:selection="{ item }">
                <div class="v-select__selection v-select__selection--comma d-flex align-center">
                  <ionic-icon 
                    :icon="item[1].info.icon"
                    class="mr-2"
                    size="24px"
                    :style="{
                      color: item[1].info.icon && item[1].info.icon.color ? item[1].info.icon.color : 'var(--main-dark-color)'
                    }"
                  /> 
                  {{ item[1].info.title }}
                </div>
              </template>
            </v-select>
            <v-btn 
              icon
              small
            >
              <v-icon
                @click="tryCreateLibrary"
                size="20"
              >
                mdi-plus
              </v-icon>
            </v-btn> 
            <v-divider style="height: 24px; min-height: 24px;" vertical class="mx-4 d-flex align-self-center"/>
            -->
            <breadcrumbs
              v-if="!isMobileDevice"
              :folder-data="currentActiveFolderData"
            >
              <template v-for="(item, index) in getActiveFolderParents">
                <breadcrumbs-item
                  v-if="
                    isMobileDevice
                      ? index === getActiveFolderParents.length - 1
                      : true
                  "
                  @goToFolder="onClickBreadcrumb"
                  :class="{
                    active: index === getActiveFolderParents.length - 1,
                  }"
                  :key="`folders-item-${item.docId}-parent-${index}`"
                  :item-data="item"
                  :chevron="index !== getActiveFolderParents.length - 1"
                  :is-mobile-device="isMobileDevice"
                  :index="index"
                  @goBack="
                    (index) => setActive([getActiveFolderParents[index].docId])
                  "
                />
              </template>
            </breadcrumbs>
          </div>
          <v-spacer></v-spacer>
          <div
            class="toolbar-layer d-flex align-center pr-2 mx-2"
            :class="[
              !currentActiveFolderData ||
              (currentActiveFolderData &&
                (currentActiveFolderData.id === 'home' ||
                  currentActiveFolderData.id === 'trash'))
                ? 'pl-4'
                : 'pl-2',
            ]"
          >
            <template
              v-if="
                !currentActiveFolderData ||
                (currentActiveFolderData &&
                  (currentActiveFolderData.id === 'home' ||
                    currentActiveFolderData.id === 'trash'))
              "
            >
              <v-menu slot="menu" min-width="200" offset-y>
                <template v-slot:activator="{ on, attr }">
                  <v-icon v-on="on" v-bind="attr"> mdi-tune-vertical </v-icon>
                </template>
                <v-list>
                  <template
                    v-if="
                      !currentActiveFolderData ||
                      (currentActiveFolderData &&
                        currentActiveFolderData.id === 'home')
                    "
                  >
                    <v-list-item
                      @click="
                        tryAdd(
                          'folder',
                          currentActiveFolderData && currentActiveFolderData.id
                            ? null
                            : findTreeNode(
                                { children: $store.getters.tree },
                                $store.getters.activeFolder[0]
                              )
                        )
                      "
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-folder-plus </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title> New folder </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                    <v-list-item
                      v-for="type in $store.getters.types"
                      :key="`folder-item-create-${type.name}`"
                      @click="() => tryCreateItem(type)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          {{ type.icon }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          Add {{ type.name.toLowerCase() }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-if="
                      currentActiveFolderData &&
                      currentActiveFolderData.id === 'trash'
                    "
                    @click="deleteAll"
                  >
                    <v-list-item-content>
                      <v-list-item-title> Удалить все </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-divider
                style="height: 24px; min-height: 24px"
                vertical
                class="mx-4 d-flex align-self-center"
              />
            </template>
            <user-profile-menu
              @logout="logout"
              @showUserPreferencesDialog="showUserPreferencesDialog"
              :fields="userPreferencesFields"
            />
          </div>
        </v-app-bar>
        <breadcrumbs
          v-if="isMobileDevice"
          :is-mobile-device="isMobileDevice"
          :folder-data="currentActiveFolderData"
          :class="{ 'mobile-breadcrumbs': isMobileDevice }"
        >
          <template v-for="(item, index) in getActiveFolderParents">
            <breadcrumbs-item
              v-if="
                isMobileDevice
                  ? index === getActiveFolderParents.length - 1
                  : true
              "
              @goToFolder="(id) => setActive([id])"
              :class="{ active: index === getActiveFolderParents.length - 1 }"
              :key="`folders-item-${item.docId}-parent-${index}`"
              :item-data="item"
              :chevron="index !== getActiveFolderParents.length - 1"
              :is-mobile-device="isMobileDevice"
              :index="index"
              @goBack="
                (index) => setActive([getActiveFolderParents[index].docId])
              "
            />
          </template>
        </breadcrumbs>
        <folder-item-menu
          v-if="foldersMenu"
          :key="`folders-menu`"
          :position-x="foldersMenuX"
          :position-y="foldersMenuY"
          v-model="foldersMenu"
          @emitAction="(actionId) => dispatchFoldersAction(actionId)"
        />
        <v-navigation-drawer
          :key="`navigation-drawer`"
          :color="
            $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'transparent'
              : 'white'
          "
          app
          clipped
          class="main-navigation-drawer"
          v-if="!isMobileDevice"
          floating
          v-model="drawer"
          width="300px"
        >
          <template v-if="showTree">
            <v-list
              rounded
              class="virtual-list"
              active-class="primary--text"
              v-model="activeFolderModel"
              v-for="(item, index) in virtualTree"
              :key="`virtual-item-${index}`"
              :subheader="!!item.subheader"
            >
              <v-subheader class="virtual-subheader d-flex align-center" style="width: 100%;" v-if="item.subheader">
                {{
                  item.subheader
                }}
                <template v-if="item.id === 'folders'">
                  <div class="ml-auto virtual-subheader-icon">
                    <v-icon
                      size="20"
                      color="grey"
                      @click="() => tryAdd('folder', null)"
                    >
                      mdi-plus
                    </v-icon>
                  </div>
                </template>
              </v-subheader>
              <template v-for="(virtualDir, index) in item.items">
                <v-list-item
                  class="d-flex align-center"
                  :ripple="false"
                  @click="() => setActive([virtualDir.id])"
                  :class="{ active: activeFolderModel.includes(virtualDir.id) }"
                  :key="`virtual-dir-${virtualDir.id}`"
                  v-if="!virtualDir.multiple"
                >
                  <v-list-item-icon class="pt-0 align-self-center">
                    <v-icon
                      :class="{
                        'primary--text': activeFolderModel.includes(
                          virtualDir.id
                        ),
                      }"
                    >
                      {{ virtualDir.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      {{ virtualDir.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list
                  v-else
                  class="virtual-dir-folders pa-0"
                  :key="`virtual-dir-subgroup-${virtualDir.id}-${index}`"
                  :color="
                    activeFolderModel.includes('home') ? 'primary--text' : null
                  "
                  v-model="foldersCollapseState"
                  sub-group
                  no-action
                >
                  <!-- <v-list-item
                    @click="onClickListGroupActivator"
                    class="d-flex align-center px-0"
                    style="pointer-events: none;"
                  >
                    <v-list-item-icon
                      class="pt-0 align-self-center pl-0"
                      style="margin-left: -1px;"
                    >
                      <v-icon
                        :style="{
                          color: activeFolderModel.includes('home') ? `#1976d2 !important` : null
                        }"
                      >
                        {{ foldersCollapseState ? `${virtualDir.icon}-open` : virtualDir.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content
                      class="py-0"
                    >
                      <v-list-item-title
                        style="margin-left: -1px;"
                        :style="{
                          color: activeFolderModel.includes('home') ? `#1976d2 !important` : null
                        }"
                      >Library</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <tree
                    v-model="tree"
                    :showFoldersMenu="showFoldersMenu"
                    @click:item="(val) => setActive([val.docId])"
                  />
                  <!-- <v-treeview
                    rounded
                    v-if="tree.length"
                    :active="activeFolderModel"
                    :open.sync="openFolderModel"
                    :items="tree"
                    :key="treeviewKey"
                    :search="searchModel"
                    @update:active="(val) => setActive(val)"
                    activatable
                    ref="tree"
                    hoverable
                    item-key="docId"
                    item-text="title"
                  >
                    <template v-slot:label="{ item }">
                      <div class="no-draggable">
                        {{ item.info.title }}
                      </div>
                      <div
                        class="v-treeview__plate"
                        :data-folder-id="item.docId"
                        :class="{
                          'dnd-over': dragoveredFolderId === item.docId,
                        }"
                        @contextmenu.prevent="(e) => showFoldersMenu(e, item)"
                      >
                        <div
                          class="v-treeview__plate-content"
                          @dragover.prevent.stop="(e) => dragoverTo(e, item)"
                          @drop.prevent.stop="(e) => dropTo(e, item)"
                          @dragleave.prevent.stop="(e) => dragleave(e, item)"
                          @dragstart.self="(e) => onDragStart(e, item)"
                        />
                      </div>
                    </template>
                  </v-treeview> -->
                </v-list>
              </template>
            </v-list>
          </template>
        </v-navigation-drawer>

        <v-main style="" :key="`app-main`">
          <router-view
            @dropTo="({ e, item }) => dropTo(e, item)"
            @goToFolder="onGoToFolder"
            @addFolder="(folder) => tryAdd('folder', folder)"
            :dragoveredFolderId="dragoveredFolderId"
            :in-add-folder-process="inAddFolderProcess"
            @clickMenu="({ e, item }) => showFoldersMenu(e, item)"
            @closeItemDialog="onCloseItemDialog"
            @showContentContextMenu="({ e, item }) => showFoldersMenu(e, item)"
          />
        </v-main>
        <create-folder-dialog
          v-model="addDialogModel"
          :key="`add-dialog`"
          :add-dialog-item="addDialogItem"
          :add-dialog-item-type="addDialogType"
          :form="form"
          v-if="addDialogModel"
          @success="add"
          :in-process="inAddFolderProcess"
        />
        <edit-folder-dialog
          :key="`edit-dialog`"
          v-model="editFolderDialogModel"
          v-if="editFolderDialogModel"
          :edit-dialog-item="editFolderDialogItem"
          :edit-dialog-type="'folder'"
          :updated="inEditFolderProcess"
          @success="edit"
        />
        <delete-folder-dialog
          :key="`delete-dialog`"
          v-model="deleteDialogModel"
          v-if="deleteDialogModel"
          @success="deleteItem"
        />
        <edit-library-dialog
          :key="`edit-library-dialog`"
          :edit-dialog-item="editLibraryDialogItem"
          v-model="editLibraryDialogModel"
          v-if="editLibraryDialogModel"
          :updated="inEditLibraryProcess"
          @success="editLibraryItem"
        />
        <div :key="`more-dialog`" class="more-dialog">
          <v-dialog v-model="mobileDrawerMore">
            <v-card flat>
              <template v-if="showTree">
                <v-list
                  rounded
                  class="virtual-list"
                  active-class="primary--text"
                  v-model="activeFolderModel"
                  v-for="(item, index) in virtualTree"
                  :key="`virtual-item-${index}`"
                  :subheader="!!item.subheader"
                >
                  <v-subheader v-if="item.subheader">{{
                    item.subheader
                  }}</v-subheader>
                  <v-list-item
                    :ripple="false"
                    @click="() => setActive([virtualDir.id])"
                    :class="{
                      active: activeFolderModel.includes(virtualDir.id),
                    }"
                    v-for="virtualDir in item.items"
                    :key="`virtual-dir-${virtualDir.id}`"
                  >
                    <v-list-item-icon class="pt-0 align-self-center">
                      <v-icon>
                        {{ virtualDir.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>
                        {{ virtualDir.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-treeview
                  rounded
                  v-show="displayTree"
                  v-if="tree.length"
                  :active="activeFolderModel"
                  :items="tree"
                  :search="searchModel"
                  @update:active="(val) => setActive(val)"
                  activatable
                  hoverable
                  item-key="docId"
                  item-text="title"
                  open-all
                >
                  <template v-slot:append="{ item }">
                    <v-menu bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click.stop.prevent="() => tryAdd('folder', item)"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              Create folder
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="() => tryEdit(item)">
                          <v-list-item-content>
                            <v-list-item-title>Edit</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item @click="() => tryDelete(item)">
                          <v-list-item-content>
                            <v-list-item-title> Delete </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:label="{ item }">
                    <div :data-folder-id="item.id">
                      {{ item.title }}
                    </div>
                  </template>

                  <template v-slot:prepend="{ item, open }">
                    <v-icon v-if="!item.file" :data-folder-id="item.id">
                      {{ open ? "mdi-folder-open" : "mdi-folder" }}
                    </v-icon>
                  </template>
                </v-treeview>
                <div v-else class="d-flex justify-center align-center">
                  <v-btn @click="() => tryAdd('folder', null)">
                    Add folder
                  </v-btn>
                </div>
              </template>
            </v-card>
          </v-dialog>
          <user-preferences-dialog
            :key="`user-preferences`"
            v-if="userPreferencesModel"
            :form="userPreferencesForm"
            :fields="userPreferencesFields"
            v-model="userPreferencesModel"
            @success="saveUserProfile"
          />
        </div>
        <mobile-bottom-navigation
          v-model="mobileDrawer"
          v-if="isMobileDevice"
          @setActive="(id) => setActive([id])"
          @success="mobileDrawerMore = true"
        />
        <update-worker-widget
          v-if="updateWorkerModel"
          v-model="updateWorkerModel"
        />
        <internet-connection-snackbar />
      </template>
      <splash-screen :key="`init-splash-screen`" v-else />
    </template>
    <template v-if="showAuth">
      <v-main>
        <v-container fluid class="py-0" style="height: 100%">
          <v-row style="height: 100%" align="center">
            <v-col class="auth-gradient d-flex align-end" cols="12">
              <v-card
                flat
                max-width="600px"
                width="100%"
                class="mx-auto"
                color="transparent"
              >
                <v-toolbar height="auto" color="transparent" flat>
                  <v-toolbar-title
                    class="
                      mx-auto
                      auth-title
                      mb-5
                      d-flex
                      flex-column
                      white--text
                    "
                  >
                    <img
                      class="auth-icon d-block mx-auto mb-3"
                      :src="require('@/assets/logo.png')"
                    />
                    <div>Inspiration Library</div>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card
                  color="transparent"
                  flat
                  id="firebase-auth-container"
                ></v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>
<script>
import MainBackground from "@/components/backgrounds/mainBackground";
import Folder from "@/classes/folder";
import Library from "@/classes/library";
import Tree from '@/components/tree'

import {
  authUiStart,
  auth,
  onUpdateUserSettings,
  onUpdateFoldersList,
  onUpdateLibrariesList,
} from "@/fb";
import md5 from "crypto-js/md5";
import { isMobile, isTablet } from "mobile-device-detect";
/*eslint no-prototype-builtins: "error"*/
import Breadcrumbs from "@/components/breadcrumbs";
import BreadcrumbsItem from "@/components/breadcrumbs/item";

const internetConnectionSnackbar = () =>
  import("@/components/widgets/internetConnectionSnackbar");

// Widgets

const folderItemMenu = () => import("@/components/widgets/folderItemMenu");
const userProfileMenu = () => import("@/components/widgets/userProfileMenu");
const updateWorkerWidget = () => import("@/components/widgets/updateWorker");

// Dialogs

const userPreferencesDialog = () =>
  import("@/components/dialogs/userPreferences");
const deleteFolderDialog = () => import("@/components/dialogs/folder/delete");
const editFolderDialog = () => import("@/components/dialogs/folder/edit");
const editLibraryDialog = () => import("@/components/dialogs/library/edit");
const createFolderDialog = () => import("@/components/dialogs/folder/create");

// Navigations

const mobileBottomNavigation = () =>
  import("@/components/navigations/mobileBottomNavigation");

import SplashScreen from "@/components/splash-screen";

export default {
  name: "App",
  components: {
    MainBackground,
    SplashScreen,
    Breadcrumbs,
    BreadcrumbsItem,

    // Widgets

    folderItemMenu,
    userProfileMenu,
    updateWorkerWidget,
    internetConnectionSnackbar,

    // Dialogs

    userPreferencesDialog,
    deleteFolderDialog,
    editFolderDialog,
    editLibraryDialog,
    createFolderDialog,

    // Navigations

    mobileBottomNavigation,
    Tree
  },
  data: () => ({
    treeviewKey: 1,
    tempTree: [],
    inCreateLibraryProcess: false,
    inEditFolderProcess: true,
    inEditLibraryProcess: true,
    notEditableUserFields: ["subscription", "email"],
    userPreferencesForm: {},
    dragoveredFolderId: null,
    openFolderModel: [],
    foldersMenu: false,
    foldersMenuX: null,
    foldersMenuY: null,
    foldersMenuItem: null,
    foldersCollapseState: true,
    updateWorkerModel: false,
    inAddFolderProcess: false,
    showAuth: false,
    inited: false,
    mobileDrawer: true,
    mobileDrawerMore: false,
    drawer: true,
    searchModel: null,
    showTree: false,
    displayTree: false,
    userPreferencesModel: false,
    addDialogModel: false,
    deleteDialogModel: false,
    addDialogType: "",
    deleteDialogType: "",
    addDialogItem: null,
    deleteDialogItem: null,
    form: {},
  }),
  created() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    auth.auth.onAuthStateChanged((user) => {
      if (user) {
        this.$store.commit("setUser", {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
          email: user.email,
        });
        onUpdateUserSettings(user.uid);
      } else {
        this.showAuth = true;
      }
    });
  },
  computed: {
    activeLibrary: {
      get() {
        return this.$store.getters.activeLibrary;
      },
      set(val) {
        this.$store.commit("setActiveLibrary", val);
      },
    },
    editFolderDialogItem: {
      get() {
        return this.$store.getters.editFolderDialogItem;
      },
      set(val) {
        this.$store.commit("setEditFolderDialogItem", val);
      },
    },
    editFolderDialogModel: {
      get() {
        return this.$store.getters.editFolderDialogModel;
      },
      set(val) {
        this.$store.commit("setEditFolderDialogModel", val);
      },
    },
    editLibraryDialogItem: {
      get() {
        return this.$store.getters.editLibraryDialogItem;
      },
      set(val) {
        this.$store.commit("setEditLibraryDialogItem", val);
      },
    },
    editLibraryDialogModel: {
      get() {
        return this.$store.getters.editLibraryDialogModel;
      },
      set(val) {
        this.$store.commit("setEditLibraryDialogModel", val);
      },
    },
    currentActiveFolderData() {
      const [activeFolderId] = this.$store.getters.activeFolder;
      const result = this.$store.getters.virtualTree.reduce((acc, item) => {
        acc = [...acc, ...item.items];
        return acc;
      }, []);
      const virtualTreeIds = result.map((item) => item.id);
      let data;
      if (virtualTreeIds.includes(activeFolderId)) {
        data = result.find((item) => item.id === activeFolderId);
      } else {
        data = null;
      }

      return data;
    },
    userPreferencesFields() {
      const user = this.$store.getters.user;
      const excludeFields = ["uid"];
      const orderFields = ["displayName", "email", "photoURL", "subscription"];
      if (!user) return [];
      const fieldNames = Object.keys(user).filter(
        (key) => !excludeFields.includes(key)
      );
      const result = fieldNames.map((name) => {
        return {
          order: orderFields.findIndex((n) => n === name),
          name,
          editable: !this.notEditableUserFields.includes(name),
        };
      });
      result.sort((a, b) => {
        const aProp = a.order;
        const bProp = b.order;

        if (aProp < bProp) {
          return -1;
        }

        if (aProp > bProp) {
          return 1;
        }

        return 0;
      });

      return result;
    },
    isMobileDevice() {
      return isMobile;
    },
    isTabletDevice() {
      return isTablet;
    },
    activeFolderModel: {
      get() {
        return this.$store.getters.activeFolder;
      },
    },
    virtualTree: {
      get() {
        return this.$store.getters.virtualTree;
      },
      set(val) {
        this.$store.commit("setVirtualTree", val);
      },
    },
    tree: {
      get() {
        const children = this.$store.getters.tree.filter(
          (folder) => !folder.service
        );
        const result = [
          ...children
        ];

        return result;
      },
    },
    user() {
      return this.$store.getters.user;
    },
    userSettings() {
      return this.$store.getters.userSettings;
    },
    getActiveFolderParents() {
      if (this.currentActiveFolderData)
        return [
          {
            title: this.currentActiveFolderData.title,
            disabled: true,
          },
        ];
      const [activeFolderId] = this.$store.getters.activeFolder;
      const findedNode = this.findTreeNode(
        { children: this.$store.getters.tree },
        activeFolderId
      );
      const result = [
        {
          title: "Library",
          disabled: false,
          docId: "home",
          icon: "mdi-folder",
        },
        ...this.findAllParentsTreeNode(findedNode),
        { ...findedNode, disabled: true },
      ];
      return result;
    },
    treeModel: {
      get() {
        return this.$store.getters.treeModel;
      },
      set(val) {
        this.$store.commit("setTreeModel", val);
      },
    },
  },
  methods: {
    onClickListGroupActivator(id) {
      console.log(id);
    },
    onGoToFolder(id) {
      this.setActive([id]);
    },
    async onClickLibraryAction(item, actionName) {
      const [id] = item;

      switch (actionName) {
        case "delete":
          await this.$store.dispatch("deleteLibrary", id);
          break;
        case "edit":
          this.editLibraryDialogModel = true;
          this.editLibraryDialogItem = item;
          break;
        default:
          break;
      }
    },
    async tryCreateLibrary() {
      this.inCreateLibraryProcess = true;

      const lib = new Library();

      lib.info.title = `Library ${this.$store.getters.libraries.length + 1}`;

      await this.$store.dispatch("createLibrary", lib);

      this.inCreateLibraryProcess = false;
    },
    onCloseItemDialog() {
      const [activeFolderId] = this.$store.getters.activeFolder;
      const result = this.$store.getters.virtualTree.reduce((acc, item) => {
        acc = [...acc, ...item.items];
        return acc;
      }, []);
      const virtualTreeIds = result.map((item) => item.id);
      const path = virtualTreeIds.includes(activeFolderId)
        ? `/${activeFolderId}`
        : `/folders/${activeFolderId}`;

      this.$router.replace({
        path,
      });
    },
    updateOnlineStatus() {
      const isOffline = !navigator.onLine;
      this.$store.commit("setIsOffline", isOffline);
    },
    findAllParentsTreeNode(item) {
      let result = [];
      const func = (item) => {
        if (!item || item.data.parentId === null) return result;
        const { parentId } = item.data;
        console.log("🚀 ~ file: App.vue:1067 ~ func ~ parentId:", parentId)
        const parentNode = this.findTreeNode(
          { children: this.$store.getters.tree },
          parentId
        );
        result.unshift(parentNode);
        func(parentNode);
      };

      func(item);

      return result;
    },
    findTreeNode(item, id) {
      if (item.docId === id) {
        return item;
      } else if (item.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < item.children.length; i++) {
          result = this.findTreeNode(item.children[i], id);
        }

        return result;
      }

      return null;
    },
    dropTo(e, to) {
      const { dataTransfer } = e;
      const item = JSON.parse(dataTransfer.getData("il-item"));
      const index = JSON.parse(dataTransfer.getData("il-item-index"));
      const { folderId } = item;
      const from = this.findTreeNode(this.$store.getters.tree[0], folderId);
      this.dragoveredFolderId = null;
      this.$store.dispatch("moveItem", {
        item,
        from,
        index,
        to,
      });
    },
    onDragStart(e) {
      console.log(e);
    },
    dragleave() {
      this.dragoveredFolderId = null;
    },
    dragoverTo(e, item) {
      const { docId } = item;
      this.dragoveredFolderId = docId;
      if (
        !this.openFolderModel.includes(docId) &&
        item.children &&
        item.children.length
      ) {
        this.openFolderModel = [...this.openFolderModel, item.docId];
      }
    },
    tryMoveTo(item) {
      const { docId } = item;
      const el = document.getElementById(`item-folder-${docId}`);
      if (!el) return;
      this.$store.commit("setMoveToDialog", {
        model: true,
        target: item,
        el,
      });
    },
    dispatchFoldersAction(actionId) {
      const foldersMenuItem = this.foldersMenuItem;
      switch (actionId) {
        case "add":
          this.tryAdd("folder", foldersMenuItem);
          break;
        case "edit":
          this.tryEdit(foldersMenuItem);
          break;
        case "moveTo":
          this.foldersMenu = false;
          this.foldersMenuX = null;
          this.foldersMenuY = null;
          this.tryMoveTo(this.foldersMenuItem);
          break;
        case "delete":
          this.tryDelete(foldersMenuItem);
          break;
      }
    },
    showFoldersMenu(e, item) {
      const { clientX, clientY } = e;
      this.foldersMenuX = clientX;
      this.foldersMenuY = clientY;
      this.foldersMenuItem = item;
      this.foldersMenu = true;
    },
    saveUserProfile() {
      const formForSend = {};
      const editableKeys = Object.keys(this.userPreferencesForm).filter(
        (key) => !this.notEditableUserFields.includes(key)
      );
      editableKeys.forEach((key) => {
        formForSend[key] = this.userPreferencesForm[key];
      });

      this.$store.dispatch("saveUserProfile", formForSend).then(() => {
        this.userPreferencesModel = false;
      });
    },
    initializationApp() {
      this.$store.dispatch("saveTreeInFb");
    },
    logout() {
      const { logout } = auth;
      logout().then((success) => {
        this.inited = !success;
        this.showAuth = success;
      });
    },
    filterForObjects(obj, predicate) {
      let result = {},
        key;

      for (key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          !predicate(obj[key])
        ) {
          result[key] = obj[key];
        }
      }

      return result;
    },
    cntFilesForItem() {
      return null;
      // const { id } = item
      // const filesForFolderId = this.filterForObjects(this.$store.getters.files, document => document.folderId !== id)
      // return Object.keys(filesForFolderId).length ? `(${Object.keys(filesForFolderId).length})` : null
    },
    onClickBreadcrumb(id) {
      if (!id) return;
      this.setActive([id]);
    },
    setActive(val = this.$store.getters.activeFolder) {
      const [id] = val;
      const currentFolderId = this.$store.getters.activeFolder[0];
      if (!id) return;
      if (id === currentFolderId) return;
      this.$store.commit("setActiveFolder", val);
      this.mobileDrawerMore = false;
      this.$store.commit("setFbUpdateListeners", []);
    },
    getMd5FromNow() {
      const result = md5(`${Date.now()}`).toString();
      return result;
    },
    getFileTemplate() {
      return {
        isFile: true,
      };
    },
    add({ type, folder, item }) {
      this.inAddFolderProcess = true;
      this.$store
        .dispatch("addItemInFolder", {
          type,
          folder,
          item,
        })
        .then((res) => {
          this.addDialogModel = !res;
          this.$nextTick(() => {
            this.inAddFolderProcess = false;
          });
        });
    },
    editLibraryItem({ id: docId, data, original }) {
      this.inEditLibraryProcess = false;
      this.$store
        .dispatch("editLibraryItem", {
          docId,
          data: {
            info: {
              ...original.info,
              ...data,
            },
          },
        })
        .then(() => {
          this.editLibraryDialogModel = false;
          this.inEditLibraryProcess = true;
        });
    },
    edit({ id: docId, data, original }) {
      this.inEditFolderProcess = false;
      this.$store
        .dispatch("editItemInFolder", {
          docId,
          data: {
            info: {
              ...original.info,
              ...data,
            },
          },
        })
        .then(() => {
          this.editFolderDialogModel = false;
          this.inEditFolderProcess = true;
        });
    },
    tryAdd(type, item) {
      console.log(item);
      this.addDialogModel = true;
      this.addDialogType = type;
      this.addDialogItem = item;
    },
    tryEdit(item) {
      this.editFolderDialogModel = true;
      this.editFolderDialogItem = item;
    },
    tryDelete(item) {
      this.deleteDialogModel = true;
      this.deleteDialogType = "folder";
      this.deleteDialogItem = item;
    },
    getAllchildrenId(folder) {
      let ids = [];
      const iterate = (item) => {
        ids.push(item.docId);
        if (item.children && item.children.length) {
          item.children.forEach(iterate);
        }
      };

      iterate(folder);

      return ids;
    },
    onClickLibrary() {
      this.setActive(["home"]);
    },
    filterNullNodes(tree) {
      let result = Object.values(tree)[0];
      if (!result) return tree;
      const iterate = (item) => {
        if (item) {
          item.children = item.children.filter((val) => val);
        }
        if (item && item.children && item.children.length) {
          item.children.forEach(iterate);
        }
      };

      iterate(result);

      return result;
    },
    deleteItem() {
      const ids = this.getAllchildrenId(this.deleteDialogItem);
      const items = ids.map(id => {
        return this.findTreeNode({ children: this.$store.getters.tree },id)
      })

      const queries = this.$store.dispatch("deleteItems", ids);
      queries.then(async () => {
        const target = items[0]
        const { parentId } = target
        const folders = await this.$store.dispatch('folderByParentId', parentId)
        console.log(folders.map(f => f[1].index))
        folders.forEach((folder, index) => {
          folder[1].index = index
        })
        console.log(folders.map(f => f[1].index))
        const updated = await Promise.allSettled(folders.map(([id, data]) => {
          return this.$store
              .dispatch("editItemInFolder", {
                docId: id,
                data: {
                  index: data.index
                },
              })
        }))
        console.log(updated)
        this.deleteDialogModel = false;
      });
    },
    showUserPreferencesDialog(data) {
      this.userPreferencesForm = {
        ...data,
      };
      this.userPreferencesModel = true;
    },
  },
  watch: {
    tree: {
      deep: true,
      handler(to) {
        this.treeviewKey += 1;
        this.tempTree = [...to]
      },
    },
    async activeLibrary(to, from) {
      if (to[0] === from[0]) return;
      if (!this.inited) return;
      this.inited = false;
      this.$store.commit("setFolders", []);
      await this.$store.dispatch("loadFolders");
      await onUpdateFoldersList(this.$store.getters.user.uid);
      this.$store.commit("setActiveFolder", ["inbox"]);
      setTimeout(() => {
        this.inited = true;
      }, 300);
    },
    user(to) {
      if (to) {
        const initCb = async () => {
          await onUpdateLibrariesList(to.uid);
          this.$store.dispatch("loadFolders").then(() => {
            onUpdateFoldersList(to.uid);
            // const { subscriptionId } = this.$store.getters.subscription;
            // this.$store.dispatch("getUserPlan", subscriptionId).then((plan) => {
            //   this.$store.commit("setPlan", plan);
            // });

            this.showAuth = false;
            this.showTree = true;
            const { folderId = null, virtualFolderId = null } =
              this.$route.params;
            const activeFolder =
              folderId ||
              virtualFolderId ||
              localStorage.getItem("il-active-folder");
            const resultFolder = activeFolder ? [activeFolder] : ["inbox"];
            this.$store.commit("setActiveFolder", {
              val: resultFolder,
              redirect: false,
            });
            this.$store.dispatch("loadFileTypes");
            this.$store.commit("setFilesInLoadProcess", false);

            // const downloads = JSON.parse(localStorage.getItem('il-downloads'))
            // if (downloads) {
            //   this.$store.commit('setListFilesInUploadProcess', downloads)
            // }
            this.inited = true;
            this.$nextTick(() => {
              this.updateWorkerModel = JSON.parse(
                localStorage.getItem("il-need-worker-update")
              );
            });
          });
        };

        initCb()

        // this.$store.dispatch("getUserSubscription").then((subscription) => {
        //   if (!subscription) {
        //     this.$store
        //       .dispatch("createUserSubscription")
        //       .then((subscription) => {
        //         this.$store.commit("setSubscription", subscription);
        //         initCb();
        //       });
        //   } else {
        //     this.$store.commit("setSubscription", subscription);
        //     initCb();
        //   }
        // });
      }
    },
    showAuth(to) {
      if (to) {
        this.$nextTick(authUiStart);
      }
    },
    addDialogModel(to) {
      const { uid: userId } = this.$store.getters.user;
      if (!to) {
        this.form = {};
      } else {
        switch (this.addDialogType) {
          case "folder":
            this.form = new Folder({ userId });
            break;
          case "file":
            this.form = {
              ...this.form,
              ...this.getFileTemplate(),
            };

            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Noto Sans";
  src: url("/fonts/notosans-light-webfont.woff2") format("woff2"),
    url("/fonts/notosans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("/fonts/notosans-medium-webfont.woff2") format("woff2"),
    url("/fonts/notosans-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

.toolbar-layer {
  background: rgba(255, 255, 255, 0.8);
  height: 48px;
  border-radius: 8px;
  transition: background-color 0.3s;
  backdrop-filter: blur(4px);

  &:hover {
    background: #fff;
  }
}

#app {
  overflow: hidden;
  font-family: "Noto Sans", sans-serif !important;
}

.library-select {
  .v-select__selections input {
    width: 0px;
  }

  .v-select__selection {
    max-width: 100%;
  }
}

.default-dialog {
  .v-toolbar,
  .v-card__actions,
  .icons-block,
  .default-padding-block {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
}

.default-dialog {
  padding: 57px 64px;

  .v-card {
    padding: 57px 64px;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: #f4f4f4;
    box-shadow: inset 0px 4px 4px rgba(230, 230, 230, 0.6);
  }

  .theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: #292929;
  }

  &__label {
    font-size: 13px;
  }

  * {
    font-family: "Noto Sans", sans-serif !important;
  }

  input,
  textarea {
    font-size: 15px;
    line-height: 24px;
  }

  .v-toolbar__content,
  .v-card__actions {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .v-card__actions {
    padding-bottom: 0px;
  }

  .v-toolbar__title {
    font-weight: 300 !important;
    font-size: 28px;
    line-height: 35px;
  }
}

.mobile .default-dialog {
  .v-toolbar__title {
    font-size: 24px;
  }
}

.mobile {
  .default-dialog {
    padding: 28.5px 32px;
    .v-card {
      padding: 28px 8px;
    }

    & > .v-btn {
      z-index: 2;
      top: 14px !important;
      right: 14px !important;
    }
  }
}

.default-dialog.px-0 {
  .v-toolbar,
  .v-card__actions,
  .icons-block,
  .default-padding-block {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
}

.mobile .default-dialog.px-0 {
  .v-toolbar,
  .v-card__actions,
  .icons-block,
  .default-padding-block {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

.v-list .v-list-item--active .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}

// body {
//   touch-action: manipulation;
//   -webkit-tap-highlight-color: transparent;
//   -webkit-font-smoothing: antialiased;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

.desktop-padding-top {
  padding-top: 64px !important;
}

.v-main {
  z-index: 2;
}

.main-navigation-drawer {
  .v-treeview-node__root {
    padding: 0px !important;
  }

  .v-treeview-node__root::before,
  .v-list-item::before {
    background-color: #1976d2 !important;
  }

  .v-treeview-node__root.v-treeview-node--active::before,
  .v-list-item.active::before {
    opacity: 0 !important;
  }
}

.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0px !important;
  padding-left: 6px;
}

.no-draggable {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.v-menu__content {
  background-color: #fff;
}

.v-list-item {
  min-height: 40px !important;

  .v-list-item-content {
    padding: 10px 0px;
  }

  .v-list-item__icon {
    margin: 0px;
    padding-top: 10px;
  }
}

.v-menu__content .v-list-item {
  .v-list-item__icon {
    padding-top: 9px;
    margin-right: 14px !important;
  }
}

.v-list-item,
.v-list-item__title {
  margin-bottom: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Helvetica Neue, sans-serif !important;
  font-size: 13px !important;
}

.app-bar-logo {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.v-navigation-drawer__content {
  margin-top: 12px;
  overflow-x: auto !important;

  .v-subheader {
    height: 40px;
    padding-top: 12px;
  }

  .v-list-group--sub-group .v-list-group__header {
    padding-left: 16px !important;
  }

  .v-treeview {
    padding-left: 8px;
    padding-right: 8px;
  }

  .v-treeview-node.v-treeview-node--rounded > .v-treeview-node__root {
    margin-top: 0px;
    padding: 0px 32px;
  }

  .v-list-item {
    font-weight: 400 !important;
  }

  .v-treeview-node__root {
    font-size: 13px !important;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif !important;
    cursor: pointer;
    min-height: 40px;
    margin-bottom: 0px !important;
    padding: 0px 0px;
  }

  .v-treeview-node__root {
    position: relative;

    .v-treeview-node__toggle {
      z-index: 50;
    }

    .v-treeview__plate {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: transparent;
      opacity: 1;
      z-index: 10;

      &-content {
        position: relative;
        width: 100%;
        height: 100%;

        &:after {
          content: "";
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: 2px dashed #1976d2;
          display: none;
        }
      }

      &.dnd-over .v-treeview__plate-content:after {
        display: block;
      }
    }
  }

  .v-list-item__icon {
    padding-left: 22px;
    margin-right: 14px !important;
  }

  .v-treeview-node__prepend {
    margin-right: 14px !important;
  }

  .v-list-group__header__prepend-icon {
    margin-right: 0px !important;
  }

  .v-list-group__header__prepend-icon i {
    width: 10px;
  }

  .v-list-group__header__prepend-icon,
  .v-list-group__header__prepend-icon + .v-list-item__icon {
    padding-left: 0px !important;
  }
}

.v-treeview-node__label {
}

.v-treeview-node__label div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-bar .v-toolbar__content {
  padding: 0px;
}

.v-subheader {
  font-size: 11px !important;
  font-weight: bold !important;
  letter-spacing: 0.1em !important;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6) !important;
}
//  .mdl-card mdl-shadow--2dp firebaseui-container

.firebaseui-container.firebaseui-id-page-callback {
  visibility: hidden;
}
.v-application ul,
.v-application ol {
  padding: 0px;
}

.auth-background {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  &__img {
    object-fit: cover;
    object-position: top;
  }

  &__backdrop {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
  }
}

.auth-title {
  font-family: Merriweather, serif;
  font-size: 36px !important;
  filter: drop-shadow(0px 2px 65px #ffffffa8);
}

.auth-icon {
  width: 128px;
  height: 128px;
}

// .auth-gradient {
//   background: rgb(255,255,255);
//   background: linear-gradient(0deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
// }

html {
  overscroll-behavior: none;
}

.v-application,
body,
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #f3f3f3 !important;
}

.v-treeview {
  font-size: 14px;
  font-family: Lora, serif;
}

.v-treeview-node__append {
  opacity: 0;
}

.theme--light.v-treeview--hoverable
  .v-treeview-node__root:hover
  .v-treeview-node__append {
  opacity: 1;
}

.service-list .v-list-item__icon {
  margin-right: 6px !important;
}

.virtual-list
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).active:before,
.virtual-dir-folders .v-list-item--active.active {
  background-color: rgb(25, 118, 210);
  opacity: 0.12;
}

.virtual-list
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).active
  .v-list-item__title {
  color: rgb(25, 118, 210) !important;
}

.pswp__bg {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 17px;
}

.main-navigation-drawer {
  .v-list-group__header__prepend-icon {
    padding-top: 0px;
    align-self: center;
  }

  .v-treeview-node.v-treeview-node--rounded .v-treeview-node__root,
  .v-treeview-node.v-treeview-node--rounded .v-treeview-node__root:before {
    border-radius: $border-radius-default !important;
  }

  .v-list--rounded .v-list-item,
  .v-list--rounded .v-list-item::before,
  .v-list--rounded .v-list-item > .v-ripple__container {
    border-radius: $border-radius-default !important;
  }
}

.virtual-subheader {
  &-icon {
    transition: opacity 0.3s;
    opacity: 0;
  }

  &:hover &-icon {
    opacity: 1;
  }
}
</style>
