import { timestamp } from '@/fb'

export default class Document {
  constructor({ userId, type, folderId }) {
    this.type = type
    this.folderId = folderId
    this.parentId = null
    this.thumbnail = {}
    this.info = {
      title: '',
      description: '',
      color: null
    }
    this.content = {}
    this.metaInfo = {
      hash: null,
      attribution: {
        title: '',
        url: '',
        authorUrl: '',
        authorName: ''
      }
    }
    this.createdAt = timestamp()
    this.updatedAt = timestamp()
    this.deleted = false
    this.schemaVersion = 1
    this.deletedAt = null
    this.userId = userId
  }
}