var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tempTree)?_c('sl-vue-tree',{ref:"tree",staticClass:"tree",attrs:{"allow-multiselect":true},on:{"select":_vm.nodeSelected,"drop":_vm.nodeDropped,"toggle":_vm.nodeToggled},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var node = ref.node;
return [_c('v-list-item',{staticClass:"d-flex px-0 align-items-center tree-item",attrs:{"input-value":node.data.docId === _vm.$store.getters.activeFolder[0],"color":"primary","ripple":false},on:{"contextmenu":function($event){$event.preventDefault();return (function (e) { return _vm.showFoldersMenu(e, node.data); }).apply(null, arguments)},"click":function($event){$event.stopPropagation();return (function () { return _vm.onSelect(node); }).apply(null, arguments)}}},[_c('v-list-item-icon',{staticClass:"pt-0 pl-2 align-self-center",staticStyle:{"margin-right":"8px !important"}},[_c('ionic-icon',{style:({
              display: 'flex !important',
              alignSelf: 'center !important',
              color:
                node.data.info.icon && node.data.info.icon.color
                  ? node.data.info.icon.color
                  : 'var(--main-dark-color)',
            }),attrs:{"size":"24px","open":node.isExpanded,"icon":node.data.info.icon,"data-folder-id":node.data.id}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(node.data.info.title))])])],1)],1)]}},{key:"toggle",fn:function(ref){
            var node = ref.node;
return [_c('span',[(node.children.length)?_c('v-icon',[_vm._v(" "+_vm._s(node.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")]):_vm._e()],1)]}}],null,false,948175060),model:{value:(_vm.tempTree),callback:function ($$v) {_vm.tempTree=$$v},expression:"tempTree"}},[_c('template',{slot:"draginfo"},_vm._l((_vm.selectedNodes),function(selectedNode){return _c('v-list-item',{key:("selected-node-" + (selectedNode.data.docId))},[_c('v-list-item-icon',[_c('ionic-icon',{style:({
                display: 'flex !important',
                alignSelf: 'center !important',
                color:
                  selectedNode.data.info.icon && selectedNode.data.info.icon.color
                    ? selectedNode.data.info.icon.color
                    : 'var(--main-dark-color)',
              }),attrs:{"size":"24px","open":selectedNode.isExpanded,"icon":selectedNode.data.info.icon,"data-folder-id":selectedNode.data.id}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(selectedNode.data.info.title)+" ")])],1)],1)}),1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }