<template>
  <div
    class="breadcrumbs-list d-flex align-center"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'BreadcrumbsList'
}
</script>
<style lang="scss">
// .breadcrumbs-list {
//   line-height: 27px;
//   min-height: 27px;
// }
</style>