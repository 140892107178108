export default {
  computed: {
    itemDialogLoading: {
      get() {
        return this.$store.getters.itemDialogLoading
      },
      set(val) {
        this.$store.commit('setItemDialogLoading', val)
      }
    },
    itemDialogModel: {
      get() {
        return this.$store.getters.itemDialogModel
      },
      set(val) {
        this.$store.commit('setItemDialogModel', val)
      }
    },
    itemDialogItem: {
      get() {
        return this.$store.getters.itemDialogItem
      },
      set(val) {
        this.$store.commit('setItemDialogItem', val)
      }
    },
    itemDialogType: {
      get() {
        return this.$store.getters.itemDialogType
      },
      set(val) {
        this.$store.commit('setItemDialogType', val)
      }
    },
    itemDialogDark() {
      const item = this.itemDialogItem
      if (item && item.type === 'image') return true
      return false
    },
    itemDialogFullscreen() {
      const item = this.itemDialogItem
      if (item && item.type === 'image') return true
      return false
    }
  }
}