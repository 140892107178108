<template>
  <div
    class="splash-screen"
  >
    <div
      class="spash-screen__content"
    >
      <img
        :src="require('@/assets/logo.png')" 
        class="splash-screen__image" 
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SplashScreen'
}
</script>
<style lang="scss">
.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  &__image {
    width: 128px;
    height: 128px;
  }
}
</style>