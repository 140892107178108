import Document from './document'

export default class Image extends Document {
  constructor(documentData) {
    super(documentData)
    this.content = {
      original: null,
      originalUrl: null
    }
  }
}